import {
    ChatBubbleLeftRightIcon,
    Cog6ToothIcon,
    HeartIcon,
    HomeIcon,
    UserCircleIcon,
    WalletIcon
} from "@heroicons/react/24/outline";
import {AdjustmentsHorizontalIcon, BellAlertIcon, ClipboardDocumentCheckIcon, ClipboardDocumentListIcon, LockClosedIcon, QuestionMarkCircleIcon, WrenchScrewdriverIcon} from "@heroicons/react/24/outline";
import {UserAuthority} from "../../features/auth/models/enums/user-authority.enum";

export const navigation = [
    {
        name: 'Home',
        href: '/',
        Icon: HomeIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    },
    {
        name: 'Messages',
        href: '/messages',
        Icon: ChatBubbleLeftRightIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    },
    {
        name: 'Creator Tools',
        href: '/cc-tools',
        Icon: WrenchScrewdriverIcon,
        accessRoles: [UserAuthority.CONTENT_CREATOR]
    },
    {
        name: 'Your wallet',
        href: '/wallet',
        Icon: WalletIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    },
    {
        name: 'My profile',
        href: '/my-profile',
        Icon: UserCircleIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    },
    {
        name: 'Settings',
        href: '/settings',
        Icon: Cog6ToothIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    },
]

export const settingsNavigation = [
    // {
    //     name: 'Notifications',
    //     href: '/notifications',
    //     Icon: BellAlertIcon,
    //     accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    // },
    // {
    //     name: 'Preferences',
    //     href: '/preferences',
    //     Icon: AdjustmentsHorizontalIcon,
    //     accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    // },
    {
        name: 'Change password',
        href: '/change-password',
        Icon: LockClosedIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    },
    {
        name: 'Support',
        href: '/support',
        Icon: QuestionMarkCircleIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    },
    // {
    //     name: 'Terms',
    //     href: '/terms',
    //     Icon: ClipboardDocumentListIcon,
    //     accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    // },
    // {
    //     name: 'Privacy policy',
    //     href: '/privacy-policy',
    //     Icon: ClipboardDocumentCheckIcon,
    //     accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    // },
    // {
    //     name: 'Advanced',
    //     href: '/advanced',
    //     Icon: WrenchScrewdriverIcon,
    //     accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    // },
]
