import React, {useContext} from "react";
import {PublicUserProfile} from "../../core/models/interfaces/public-user-profile.interface";
import TextStatusUser from "./text-status-user";
import {UserProfileContext, UserProfileContextProps} from "../contexts/user-profile-context";
import {LinkIcon} from "@heroicons/react/24/solid";
import {ClipboardDocumentIcon} from "@heroicons/react/24/outline";
import {environment} from "../../../environments/environment";
import {extractDomain} from "../../../utils/extract-base-url";
import ClipboardHelper from "../../../utils/clipboard-helper";
import NotificationService from "../../common/services/notification.service";
import useAuth from "../../auth/hooks/use-auth";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";

interface ShortUserDataProps {
    fullName?: string,
    username: string,
    shortDescription?: string,
    isEditable?: boolean,
    isOwnProfile?: boolean,
}

const ShortUserData = (props: ShortUserDataProps) => {
    const {
        shortDescription,
        username,
        fullName,
        isEditable = false,
        isOwnProfile = false,
    } = props
    const {hasAnyAuthorities} = useAuth();
    const urlPublicProfile = `${extractDomain(environment.backendUrl, 3000)}/profile/${username}`
    const {isOnline} = useContext(UserProfileContext) as UserProfileContextProps<PublicUserProfile>;

    const handleCopyLink = async (url: string) => {
        await ClipboardHelper.copyText(url,
            () => NotificationService.success('Link has been copied to clipboard!', "Success", 5000),
            (error) => NotificationService.error('Failed to copy link', error, 'Error', 5000)
        );
    };

    return (
        <div className='flex flex-col items-center md:items-start'>
            <div className='font-extrabold text-[1.75rem] break-all max-w-full'>
                {fullName}
            </div>
            <div className='text-gray font-medium text-xs flex gap-2 items-center max-w-full'>
                <span className='truncate'>@{username}</span>
                {!isEditable && <span className='block w-1 h-1 bg-special-gray rounded-full'></span>}
                {!isEditable && <TextStatusUser isOnline={isOnline}/>}
            </div>
            {hasAnyAuthorities([UserAuthority.CONTENT_CREATOR]) && isEditable && <div className='w-10 h-[1px] bg-special-gray my-3'></div>}
            {!isEditable && shortDescription && (
                <>
                    <div className='w-10 h-[1px] bg-special-gray my-3'></div>
                    <div className='text-sm text-gray font-medium'>
                        {shortDescription}
                    </div>
                </>
            )}
            {hasAnyAuthorities([UserAuthority.CONTENT_CREATOR]) && isOwnProfile && (
                <div className='flex gap-2 text-gray items-center justify-center md:justify-start text-xs w-full'>
                    <div className='flex gap-1 text-gray items-center overflow-hidden'>
                        <LinkIcon className='w-3 min-w-3'/>
                        <span
                            className='truncate flex-shrink'>{urlPublicProfile}</span>
                    </div>
                    <span
                        onClick={() => handleCopyLink(urlPublicProfile)}
                        className='cursor-pointer hover:text-primary flex min-w-8 items-center justify-center bg-gray-light h-8 w-8 rounded-xl'>
                        <ClipboardDocumentIcon className='w-4'/>
                    </span>
                    {/*TODO handle this action in the future*/}
                    {/*<span*/}
                    {/*    className='cursor-pointer hover:text-primary flex items-center justify-center bg-gray-light h-8 w-8 rounded-xl'>*/}
                    {/*    <PencilIcon className='w-4 h-4'/>*/}
                    {/*</span>*/}
                </div>
            )}
        </div>
    )
}

export default ShortUserData
