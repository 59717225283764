import {useEffect, useState} from 'react';
import {LocalStorageKeys} from "../models/enums/local-storage-keys.enum";
import LocalStorageService from "../services/local-storage.service";

interface UseStepperProps {
    initialStep?: number;
    maxSteps: number;
    storageKey?: LocalStorageKeys;
}

const useStepper = ({initialStep = 0, maxSteps, storageKey}: UseStepperProps) => {
    const [currentStep, setCurrentStep] = useState<number>(() => {
        return storageKey ? (parseInt(LocalStorageService.get(storageKey)) || 0) : initialStep;
    });

    useEffect(() => {
        console.log(currentStep)
        if (storageKey && currentStep > 0) {
            LocalStorageService.save(storageKey, currentStep)
        }
    }, [currentStep, storageKey]);

    const nextStep = () => setCurrentStep((prev) => Math.min(prev + 1, maxSteps - 1));
    const previousStep = () => setCurrentStep((prev) => Math.max(prev - 1, 0));
    const goToStep = (step: number) => setCurrentStep(step);
    const resetSteps = () => setCurrentStep(0);

    return {
        currentStep,
        nextStep,
        previousStep,
        goToStep,
        resetSteps,
        isLastStep: currentStep === maxSteps - 1,
        isFirstStep: currentStep === 0,
    };
};

export default useStepper;
