import React from "react";
import {useMediaQuery} from "react-responsive";
import {useSidebar} from "../hooks/use-sidebar";
import {SidebarIds} from "../types/sidebar-ids.interface";
import {ReactComponent as HamburgerIcon} from "@assets/icons/hamburger.svg"

const AppHamburgerMenu = () => {
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    const { openSidebar } = useSidebar();

    return (
        <div className='z-[5] !text-black'>
            {!isDesktop && (
                <button
                    onClick={() => openSidebar(SidebarIds.MENU)}
                    className="flex items-center justify-center w-10 h-10 p-2 rounded-2xl
                    hover:!bg-gray-100 focus:outline-none"
                    style={{
                        background:'#e1d7fd',
                        color: 'var(--black)'
                    }}
                >
                    <HamburgerIcon className='min-w-3 w-3 h-3'/>
                </button>
            )}
        </div>
    );
}

export default AppHamburgerMenu;
