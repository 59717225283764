import {StepOnBoarding} from "../models/interfaces/step-on-boarding.interface";

import analyzeYourSituationWithAdvancedStatistics from "@assets/illustrations/analyze-your-situation-with-advanced-statistics.png";
import beAlsoAFan from "@assets/illustrations/be-also-a-fan.png";
import createADigitalProductsToIncreaseFansInterest from "@assets/illustrations/create-a-digital-products-to-increase-fan_s-interest.png";
import makeMoneyByRespondingToFansMessages from "@assets/illustrations/make-money-by-responding-to-fan_s-messages.png";
import manageYourContactPricing from "@assets/illustrations/manage-your-contact-pricing.png";
import setSubscriptionRateAndRulesToGetBiggerIncome from "@assets/illustrations/set-subscription-rate-and-rules-to-get-bigger-income.png";
import yourMoneyInYourPocketAt24h from "@assets/illustrations/your-money-in-your-pocket-at-24h.png";


export const ccOnboardingSteps: StepOnBoarding[] = [
    {
        header: 'Make money by responding to fan’s messages',
        subHeader: 'Earn income by engaging with fans through messages.',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since',
        illustration: makeMoneyByRespondingToFansMessages,
    },
    {
        header: 'Manage your contact pricing',
        subHeader: 'Effortlessly manage your contact pricing with our intuitive tools.',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since',
        illustration: manageYourContactPricing,
    },
    {
        header: 'Set subscription rate and rules to get bigger income',
        subHeader: 'Customize your subscription rate and policies to maximize your revenue.',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since',
        illustration: setSubscriptionRateAndRulesToGetBiggerIncome,
    },
    {
        header: 'Create a digital products to increase fan’s interest',
        subHeader: 'By offering exclusive content, behind-the-scenes access, and interactive challenges, you can boost fan\'s interest and keep them coming back for more.',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since',
        illustration: createADigitalProductsToIncreaseFansInterest,
    },
    {
        header: 'Analyze your situation with advanced statistics',
        subHeader: 'Gain valuable insights into your circumstances using cutting-edge statistical analysis.',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since',
        illustration: analyzeYourSituationWithAdvancedStatistics,
    },
    {
        header: 'Your money in your pocket at 24h',
        subHeader: 'We offer fast withdraw. Access your funds within 24 hours.',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since',
        illustration: yourMoneyInYourPocketAt24h,
    },
    {
        header: 'Be also a fan',
        subHeader: 'Join our community of enthusiasts and stay up to date with the latest news, updates, and exclusive content. Be also a fan and connect with like-minded individuals who share your passion.',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since',
        illustration: beAlsoAFan,
    },
]
