import {StripePrice} from "../../core/models/interfaces/stripe-price.interface";
import Coin from "../../core/components/coin";
import AppButton from "../../common/components/app-button";
import {classNames} from "../../../utils/class-names";
import SpecialOfferLabel from "./special-offer-label";
import {SpecialOfferType} from "../models/enums/special-offer-type.enum";
import Tooltip from "../../common/components/tooltip";
import {useWalletApi} from "../../core/hooks/use-wallet-api";

interface OfferPriceCardProps {
    price: StripePrice
}

const OfferPriceCard = (props: OfferPriceCardProps) => {
    const {price} = props;
    const {createCheckoutSession} = useWalletApi();

    const discount = price.metadata?.discount || null;
    const hasSavings = !!price.metadata?.savings;
    const isBestBuy = !!price.metadata?.bestBuy;
    const isHotPromotion = !!price.metadata?.discount;
    const originalPrice = price.unitAmount / 100;
    // @ts-ignore
    const discountedPrice = discount ? (originalPrice * (1 - discount / 100)).toFixed(2) : originalPrice.toFixed(2);

    //TODO wszelkie obliczenia przeniesc do CreditsServicu, pozmieniać meteddane prices, tak samo wyliczanie ceny po przecenie
    const priceOnceYCredit = price.transformQuantity && price.transformQuantity?.divideBy
        ? (price.unitAmount / 100 / price.transformQuantity.divideBy).toFixed(2)
        : (price.unitAmount / 100).toFixed(2);

    const creatCheckoutSessionHandler = async () =>{
        await createCheckoutSession(price.id)
            .then(res => {
                window.location.href = res.checkoutUrl;
            })
    }

    //TODO rednerowac dodatkow przy isHotPromotion cene z 30 ostatnich dni pod przyciskiem buy
    //TODO oraz wyliczyć na ile to przecena jest
    return (
        <div
            className={classNames("rounded-2xl",
                isBestBuy
                    ? "bg-secondary-pastel border-secondary-pastel"
                    : "border border-special-gray",
            )}
        >
            <div className={classNames(
                'w-full flex gap-1',
                (isHotPromotion || isBestBuy || hasSavings) && "p-1"
            )}>
                <SpecialOfferLabel offerType={SpecialOfferType.BEST_BUY} isVisible={isBestBuy}/>
                <SpecialOfferLabel offerType={SpecialOfferType.HOT_PROMOTION} isVisible={isHotPromotion}/>
                <SpecialOfferLabel offerType={SpecialOfferType.SAVINGS} isVisible={hasSavings}
                                   savings={price.metadata?.savings}/>
            </div>

            <div className='flex justify-between items-center p-4'>
                <div className='flex gap-3 justify-center items-center'>
                    <span className='text-[2rem]'><Coin/></span>
                    <div className='flex flex-col'>
                        <div className='flex gap-1 items-center h-fit'>
                            <span className="text-lg font-bold leading-tight">
                                {price?.transformQuantity?.divideBy}
                            </span>
                            <span className="text-xs font-bold block relative top-[1px]">
                                yC
                            </span>
                        </div>
                        <span className="text-xs text-gray">
                            1yC = {priceOnceYCredit}
                        </span>
                    </div>
                </div>

                <div className='flex flex-col items-end gap-1'>
                    <AppButton
                        onClick={creatCheckoutSessionHandler}
                        label=''
                        className='bg-primary-pastel !rounded-2xl text-primary font-semibold text-sm flex flex-col items-center
                        hover:bg-primary hover:text-white transition focus:bg-primary focus:text-white'
                    >
                        {isHotPromotion && (
                            <span className="block text-xs font-medium line-through leading-tight">
                            ${originalPrice.toFixed(2)}
                        </span>
                        )}
                        <span className='block leading-tight'>{`Buy ($${discountedPrice})`}</span>
                    </AppButton>
                    {isHotPromotion && (
                        <div className='text-special-gray text-xs font-medium flex items-center'>
                            <Tooltip/>
                            <span>30 days lowest price: $39.50</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default OfferPriceCard
