import * as yup from "yup";
import { DeliveryType } from "../../core/models/enums/delivery-type.enum";
import { DigitalProductType } from "../../core/models/enums/digital-product-types.enum";

export const schemaAddProduct = yup.object().shape({
    type: yup
        .string()
        .oneOf(Object.values(DigitalProductType), 'Invalid type')
        .required('Personal experience type is required'),

    activityTime: yup
        .number()
        .nullable()
        .transform((value, originalValue) => {
            return typeof originalValue === 'string' && originalValue.trim() === '' ? null : value;
        })
        .when("type", {
            is: (val: string) => val !== 'FILE' && val !== 'TEXT',
            then: (schema) => schema.required('Activity time is required').typeError('Activity time must be a number'),
            otherwise: (schema) => schema.notRequired(),
        }),

    title: yup
        .string()
        .required('Title is required'),

    description: yup
        .string()
        .max(1000, 'Max length is 1000 characters')
        .required('Description is required'),

    price: yup
        .number()
        .nullable()
        .typeError('Price must be a number')
        .required('Price is required')
        .min(0, 'Price must be a positive number'),

    deliveryTimeInDays: yup
        .number()
        .nullable()
        .transform((value, originalValue) => {
            return typeof originalValue === 'string' && originalValue.trim() === '' ? null : value;
        })
        .when("deliveryType", {
            is: 'MANUAL',
            then: (schema) => schema.required('Delivery time is required for manual delivery').typeError('Delivery time must be a number'),
            otherwise: (schema) => schema.notRequired(),
        }),

    deliveryType: yup
        .string()
        .nullable()
        .oneOf(Object.keys(DeliveryType), 'Invalid delivery type')
        .when("type", {
            is: (val: string) => val === 'FILE' || val === 'TEXT',
            then: (schema) => schema.required('Delivery type is required'),
            otherwise: (schema) => schema.notRequired(),
        }),

    content: yup
        .string()
        .nullable()
        .when(["type", "deliveryType"], {
            is: (type: string, deliveryType: string) => type === 'TEXT' && deliveryType === 'AUTOMATIC',
            then: (schema) => schema.required('Content is required'),
            otherwise: (schema) => schema.notRequired(),
        }),

    files: yup
        .array()
        .of(
            yup.mixed().nullable()
                .test("fileSize", "File must be under 50MB", (file: any) => {
                    if (!file) return false;
                    return file.size <= 50 * 1024 * 1024; // 50 MB
                })
                .test("fileType", "Only pdf, zip, jpg, png, svg files are allowed", (file: any) => {
                    if (!file) return false;
                    const allowedTypes = ["pdf", "zip", "jpg", "png", "svg"];
                    const fileType = file.name?.split('.').pop().toLowerCase();
                    return allowedTypes.includes(fileType);
                })
        )
        .nullable()
        .when(["type", "deliveryType"], {
            is: (type: string, deliveryType: string) => type === 'FILE' && deliveryType === 'AUTOMATIC',
            then: (schema) => schema
                .required('At least one file is required')
                .min(1, 'At least one file is required')
                .max(1, 'Only one file is allowed')
                .test("exactlyOneFile", "Exactly one file is required", (files: any) => {
                    return Array.isArray(files) && files.length === 1;
                }),
            otherwise: (schema) => schema.notRequired(),
        }),
}).required();
