import {useRoutes} from 'react-router-dom';
import AppLandingPage from "./pages/app-landing-page";
import GuardAuthRoutes from "./features/auth/components/guard-auth-routes";
import GuardPrivateRoutes from "./features/auth/components/guard-private-routes";
import LoginPage from "./pages/login-page";
import RegisterPage from "./pages/register-page";
import ForgotPasswordPage from "./pages/forgot-password-page";
import NotFoundPage from "./pages/not-found-page";
import AppPublicProfilePage from "./pages/app-public-profile-page";
import BecomeCreatorPage from "./pages/become-creator-page";
import React from "react";
import {UserAuthority} from "./features/auth/models/enums/user-authority.enum";
import GuardRestrictedRoute from "./features/auth/components/guard-restricted-route";
import OnBoardingPage from "./pages/on-boarding-page";
import GuardOnBoarding from "./features/core/guards/guard-on-boarding";
import MainLayer from "./layout/app-main";

const AppRoutes = () => {
    return useRoutes(
        [
            {
                path: "/profile/:username",
                element: <AppPublicProfilePage/>
            },
            {
                path: '/welcome',
                element: <GuardAuthRoutes/>,
                children: [
                    {path: "", element: <AppLandingPage/>,},
                ],
            },
            {
                path: '/auth',
                element: <GuardAuthRoutes/>,
                children: [
                    {path: 'login', element: <LoginPage/>},
                    {path: 'register', element: <RegisterPage/>},
                    {path: 'forgot-password', element: <ForgotPasswordPage/>},
                ],
            },
            {
                path: '/*',
                element: <GuardPrivateRoutes/>,
                children: [
                    {
                        element: <GuardOnBoarding/>,
                        children: [
                            {
                                path: 'become-creator',
                                element:
                                    <GuardRestrictedRoute requiredRoles={[UserAuthority.FAN]}>
                                        <BecomeCreatorPage/>
                                    </GuardRestrictedRoute>
                            },
                            {path: "*", element: <MainLayer/>},
                        ]
                    },
                    {path: "on-boarding", element: <OnBoardingPage/>},
                ],
            },
            {path: 'not-found', element: <NotFoundPage/>},
        ]
    )
};

export default AppRoutes;
