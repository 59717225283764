import ApiService from "../../../axios/api.service";
import NotificationService from "../../common/services/notification.service";

class UserApiService extends ApiService {

    public async changeFanOnBoarding(): Promise<void> {
        return await this.put<void>(`users/fan-on-boarding`, {})
            .catch((error) => {
                NotificationService.error('Failed to end fan onboarding.');
                throw error;
            });
    }

    public async changeCcOnBoarding(): Promise<void> {
        return await this.put<void>(`users/cc-on-boarding`, {})
            .catch((error) => {
                NotificationService.error('Failed to end content creator onboarding.');
                throw error;
            });
    }
}

export default new UserApiService();
