import React, {useEffect, useState} from 'react';
import {useFormContext} from "react-hook-form";
import SkeletonFormField from "./skeleton-form-field";
import {classNames} from "../../../../utils/class-names";

interface DescriptionFormFieldProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    name: string;
    label: string;
    maxLength: number;
    className?: string;
    isLoading?: boolean;
    isRequired?: boolean;
    forceDisabled?: boolean;
}

const DescriptionFormField: React.FC<DescriptionFormFieldProps> = (props: DescriptionFormFieldProps) => {
    const {register, watch, formState: {errors, isSubmitting, disabled}, getValues, setValue} = useFormContext();
    const {
        name,
        label,
        maxLength,
        className = '',
        isLoading,
        isRequired = false,
        forceDisabled = false,
        ...rest
    } = props;
    const currentValue = watch(name);
    const [charCount, setCharCount] = useState(getValues(name)?.length);

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(name, event.target.value, {shouldDirty: true});

        event.target.style.height = 'auto';
        event.target.style.height = `${event.target.scrollHeight}px`;
    };

    useEffect(() => {
        const textarea = document.getElementById(name) as HTMLTextAreaElement;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }, [name,currentValue]);

    useEffect(() => {
        setCharCount(currentValue?.length || 0);
    }, [currentValue]);

    return (
        <div className='w-full'>
            <div className={`relative w-full`}>
                <label htmlFor={name} className={classNames(
                    "absolute block text-xs font-medium top-2 left-[14px]",
                    (forceDisabled || disabled) && "text-gray"
                )}>
                    {label}
                    {isRequired && <span className='text-red-500'>*</span>}
                </label>
                {isLoading
                    ? <SkeletonFormField/>
                    : <>
                        <textarea
                            id={name}
                            {...register(name)}
                            placeholder="Write your description"
                            maxLength={maxLength}
                            onChange={handleInputChange}
                            className={classNames(
                                `overflow-hidden border-2 border-gray-light rounded-xl px-3 py-2 pt-6 appearance-none w-full focus:outline-none focus:ring-2 focus:ring-primary resize-none ${errors[name] ? 'border border-red-400 focus:ring-red-400' : 'border-gray-300 focus:ring-primary_400'} ${className}`,
                                (forceDisabled || disabled) && "bg-gray-light text-gray"
                            )}
                            disabled={isSubmitting || forceDisabled || disabled}
                            {...rest}
                        />
                        <div className="absolute top-[8px] right-[12px] text-right text-gray text-xs">
                            {charCount || 0} / {maxLength}
                        </div>
                    </>
                }
            </div>
            {errors[name] && <p className="text-red-500 text-xs">{'' + errors[name]?.message}</p>}
        </div>
    );
};

export default DescriptionFormField;
