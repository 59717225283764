import LoginForm from "../features/auth/components/login-form";
import AppWrapper from "../layout/components/app-wrapper";
import Logo from "../features/core/components/logo";

const LoginPage = () => {
    return (
        <AppWrapper className='h-dvh overflow-clip flex flex-col'>
            <div className='hidden md:block p-6 pb-0'>
                <Logo type='complete' className='w-[6rem]'/>
            </div>
            <div className='overflow-y-auto h-full'>
                <LoginForm/>
            </div>
        </AppWrapper>
    )
}

export default LoginPage
