import React, {FormEvent} from 'react';
import {FormProvider} from 'react-hook-form';
import NumberFormField from "../../common/components/forms/number-form-field";
import SelectFormField from "../../common/components/forms/select-form-field";
import DescriptionFormField from "../../common/components/forms/description-form-field";
import CreditsConversionService from "../../purchases/services/credits-conversion.service";
import Tooltip from "../../common/components/tooltip";
import FileUpload from "../../common/components/forms/file-upload";
import {Uploady} from "@rpldy/uploady";
import {useFormDigitalProduct} from "../hooks/use-form-digital-product.hook";
import {classNames} from "../../../utils/class-names";

interface ProductFormProps {
    classNameForm?: string;
}

const ProductForm = (props: ProductFormProps) => {
    const {methods, loading} = useFormDigitalProduct();
    const handlePriceChange = (e: any) => {
        methods.setValue('price', parseInt(e.target.value, 10));
    };
    const {classNameForm} = props;

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => e.preventDefault();

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit} className={classNames(
                'flex flex-col gap-3 pb-24',
                classNameForm && classNameForm
            )}>
                <SelectFormField isLoading={loading} name="type" label="Personal experience" options={[
                    {value: "PRIVATE_CHAT", label: "Private chat"},
                    {value: "PRIVATE_VOICE_CALL", label: "Private voice call"},
                    {value: "PRIVATE_VIDEO_CALL", label: "Private video call"},
                    {value: "FILE", label: "File"},
                    {value: "TEXT", label: "Text"},
                ]}/>

                <DescriptionFormField isLoading={loading} maxLength={255} name="title" label="Title"
                                      placeholder="Enter label"/>

                <DescriptionFormField isLoading={loading} name="description" maxLength={255} label="Description"
                                      placeholder="Enter description"/>

                {methods.watch("type") !== 'FILE' && methods.watch("type") !== 'TEXT' && (
                    <NumberFormField
                        isLoading={loading}
                        min={1}
                        name="activityTime"
                        label="Activity time (days)"
                        placeholder="Enter activity time"/>
                )}

                {(methods.watch("type") === 'FILE' || methods.watch("type") === 'TEXT') && (
                    <SelectFormField isLoading={loading} name="deliveryType" label="Delivery type" options={[
                        // {value: "MANUAL", label: "Manual"},
                        {value: "AUTOMATIC", label: "Automatic"}
                    ]}/>
                )}

                {methods.watch("type") === 'TEXT' && methods.watch("deliveryType") === 'AUTOMATIC' && (
                    <DescriptionFormField isLoading={loading} maxLength={255} name="content" label="Content"
                                          placeholder="Enter content"/>
                )}

                {methods.watch("type") === 'FILE' && methods.watch("deliveryType") === 'AUTOMATIC' && (
                    <Uploady
                        multiple={true}
                        maxConcurrent={1}
                    >
                        <>
                            <FileUpload
                                autoUpload={false}
                                name="files"
                                maxFiles={10}
                                maxFileSize={50 * 1024 * 1024} // 50MB
                                allowedExtensions={['pdf', 'zip', 'jpg', 'png', 'svg']}
                                singleFile={true}
                                invalid={!!methods.formState.errors['files']}
                            />
                            {methods.formState.errors['files'] &&
                                <p className="text-red-500 text-xs">
                                    {'' + methods.formState.errors['files']?.message}
                                </p>
                            }
                        </>
                    </Uploady>
                )}

                <div className='space-y-4'>
                    <header className='text-lg font-semibold'>Settings</header>
                    <div>
                        <span className='font-semibold text-[1rem]'>
                            Price
                            <Tooltip classNameIcon='relative top-[0.1rem] text-special-gray text-[0.85rem]'/>
                        </span>
                        <NumberFormField isLoading={loading} name="price" label="Price for all fans" suffix='yC' min={0}
                                         onChange={handlePriceChange}/>

                        <span className="text-gray-500 text-xs text-gray mx-2">
                            Approximately {CreditsConversionService.convertYCreditsToDollars(methods.watch('price'))}
                        </span>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};
export default ProductForm;
