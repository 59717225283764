import React, {useEffect, useState} from 'react';
import {useBatchAddListener, useItemStartListener} from "@rpldy/uploady";
import UploadButton from "@rpldy/upload-button";
import {XMarkIcon} from "@heroicons/react/20/solid";
import Tooltip from "../tooltip";
import {useFormContext} from 'react-hook-form';
import SpinnerLoader from "../spinner-loader";
import NotificationService from "../../services/notification.service";
import {classNames} from "../../../../utils/class-names";

interface FileUploadProps {
    maxFiles?: number;
    autoUpload: boolean;
    maxFileSize?: number; // in bytes
    allowedExtensions?: string[];
    singleFile?: boolean;
    onFilesUploaded?: (files: File[]) => void;
    name?: string;
    invalid?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = (props: FileUploadProps) => {
    const {setValue,clearErrors, getValues} = useFormContext();
    const [files, setFiles] = useState<File[]>(getValues(props.name!) || []);
    const [loading, setLoading] = useState(false);
    const {
        maxFiles = 10,
        maxFileSize = 50 * 1024 * 1024,
        allowedExtensions = ['pdf', 'zip', 'jpg', 'png', 'svg'],
        singleFile = false,
        autoUpload = false,
        invalid = false,
        onFilesUploaded,
        name
    } = props;

    useEffect(() => {
        if (name) {
            setValue(name, files);
        }
    }, [files, name, setValue]);

    useItemStartListener(() => {
        setLoading(true);
    });

    useBatchAddListener((batch) => {
        const newFiles = batch.items.map(item => item.file as File);
        setLoading(false);

        const validFiles = newFiles.filter(file => {
            const extension = file.name.split('.').pop()?.toLowerCase();

            if (file.size > maxFileSize) {
                NotificationService.warning(`File size must be less than ${maxFileSize / (1024 * 1024)} MB`, 'Warning', 6000)
                return false;
            }

            if (extension && !allowedExtensions.includes(extension)) {
                NotificationService.warning(`Only files with the following extensions are allowed: ${allowedExtensions.join(', ')}`, 'Warning', 6000)
                return false;
            }

            return true;
        });

        clearErrors(name);

        if (singleFile) {
            setFiles(validFiles.slice(0, 1));
        } else {
            setFiles(prevFiles => {
                const combinedFiles = [...prevFiles, ...validFiles];
                return combinedFiles.slice(0, maxFiles);
            });
        }

        if (onFilesUploaded) {
            onFilesUploaded(files);
        }
    });

    const handleRemoveFile = (index: number) => {
        setFiles(prev => prev.filter((_, i) => i !== index));
    };

    return (
        <div className={classNames(
            "border-[2px] border-dashed  rounded-lg p-4 w-full relative",
            invalid ? "border-red-400" : "border-gray-light"
        )}>
            <h3 className="font-semibold text-lg">
                {singleFile ? 'File' : 'Files'}
            </h3>
            <div className="flex gap-1">
                <Tooltip classNameIcon="!m-0 text-[0.9rem] text-special-gray"/>
                <p className="text-xs text-special-gray mb-4">
                    {singleFile ? 'Up to file per product' : `Up to ${maxFiles} files per product`}<br/>
                    Max. {maxFileSize / (1024 * 1024)}Mb/file in {allowedExtensions.join(', ')} format
                </p>
            </div>

            <UploadButton
                autoUpload={autoUpload}
                className="w-full bg-primary-pastel font-semibold text-primary py-2 rounded-lg
                    hover:bg-primary hover:text-white focus:bg-primary focus:text-white transition"
            >
                <>
                    {!singleFile && "+ Add files"}
                    {singleFile && files.length ? "Swap file" : "+ Add file"}
                </>
            </UploadButton>

            {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 rounded-lg">
                    <SpinnerLoader color="var(--primary)" size="16px"/>
                </div>
            )}

            <div className="mt-4">
                {files.length > 0 && (
                    <ul className="list-disc">
                        {files.map((file, index) => (
                            <li key={index}
                                className="flex items-center justify-between bg-purple-50 p-2 text-primary-darken rounded-lg mb-2">
                                <span className="text-sm truncate">{file.name}</span>
                                <button onClick={() => handleRemoveFile(index)}>
                                    <XMarkIcon className="w-5 h-5"/>
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default FileUpload;
