import ApiService, {CustomAxiosRequestConfig} from "../../../axios/api.service";
import {Authentication} from "../models/interfaces/authentication.interface";
import LocalStorageService from "../../common/services/local-storage.service";
import {LocalStorageKeys} from "../../common/models/enums/local-storage-keys.enum";
import {AuthenticationResponse} from "../models/interfaces/authentication-response.interface";
import {UserAuthority} from "../models/enums/user-authority.enum";
import {ResetPasswordBodyRequest} from "../models/interfaces/reset-email-body.interface";
import {AuthenticatedUser} from "../models/interfaces/authenticated-user.interface";
import {ChangePasswordBodyRequest} from "../models/interfaces/change-password-body.interface";
import NotificationService from "../../common/services/notification.service";
import {RegisterBody} from "../models/interfaces/register-body.interface";

class AuthService extends ApiService {

    private authentication: Authentication | null = this.loadAuthenticationFromLocalStorage();
    private onLogoutCallback: (() => void) | null = null;

    constructor() {
        super();
    }

    public async authenticate(email: string, password: string): Promise<string> {
        const authHeader: string = 'Basic ' + btoa(`${email}:${password}`);
        const httpOptions: CustomAxiosRequestConfig = {
            headers: {
                'Authorization': authHeader
            },
            params: {
                frontend: 'application',
            },
            errorMessage: "Invalid login or password.",
            skipDefault401Handling: true,
        };

        if (!this.isAuthenticated()) {
            this.authentication = null;
            LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
        }

        return await this.post<AuthenticationResponse>('/authenticate', {}, httpOptions)
            .then((res: AuthenticationResponse): string => {
                this.authentication = this.prepareAuthentication(res);
                LocalStorageService.save(LocalStorageKeys.AUTHENTICATION, JSON.stringify(this.authentication));
                return "Success"
            })
            .catch(err => {
                throw err
            })
    }

    public async registerAccount(body: RegisterBody): Promise<void> {
        return await this.post<void>(`/users/registration`, body, {})
            .then((res) => {
                NotificationService.success('Account has been successfully registered.')
            })
    }

    public async handleAdminTokenLogin(token: string): Promise<void> {
        const httpOptions: CustomAxiosRequestConfig = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            successMessage: "You have been logged in as the selected user!",
            errorMessage: "Login as the selected user failed.",
            skipDefault401Handling: true,
        };

        if (!this.isAuthenticated()) {
            this.authentication = null;
            LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
        }

        return await this.get<AuthenticatedUser>('/authenticated-user', httpOptions)
            .then((res: AuthenticatedUser): void => {
                if (res) {
                    let now: Date = new Date();
                    now.setMinutes(now.getMinutes() + 30);
                    now = new Date(now);
                    this.authentication = {user: res, token: {value: token, validUntil: now}};
                    LocalStorageService.save(LocalStorageKeys.AUTHENTICATION, JSON.stringify(this.authentication));
                }
            });
    }

    public async sendEmailConfirmationToken(email: string): Promise<void> {
        return await this.post<void>('/send-email-confirm-token', {email}, {
            skipDefault404Handling: true,
            successMessage: 'An activation link has been sent to the provided email address!',
            errorMessage: "Failed to send an activation link to the provided email address."
        }).then();
    }

    public async activateAccount(userId: string, token: string): Promise<void> {
        return await this.put<void>(`/activate-account/${userId}`, {token}, {
            skipDefault404Handling: true,
            successMessage: 'The account has been activated!',
            errorMessage: "Failed to activate the account."
        }).then();
    }

    public async getTokenResetPassword(email: string): Promise<void> {
        return await this.post<void>('/send-password-reset-token', {email}, {
            skipDefault401Handling: true,
            skipDefault404Handling: true,
            isPublic: true,
            successMessage: "A reset token has been sent to the provided email address!",
            errorMessage: "Failed to send a reset token to the provided email address."
        })
            .then((): void => {
                LocalStorageService.remove(LocalStorageKeys.EMAIL_RESET_PASSWORD);
                LocalStorageService.save(LocalStorageKeys.EMAIL_RESET_PASSWORD, email);
            });
    }

    public async resetPassword(body: ResetPasswordBodyRequest): Promise<void> {
        return await this.put<void>('/reset-password', body, {
            isPublic: true,
            skipDefault401Handling: true,
            skipDefault404Handling: true,
            successMessage: "Your password has been reset! You can now log in.",
        })
            .then((): void => {
                LocalStorageService.remove(LocalStorageKeys.EMAIL_RESET_PASSWORD);
            });
    }

    public async changePassword(body: ChangePasswordBodyRequest): Promise<void> {
        return await this.put<void>('/change-password', body, {
            skipDefault401Handling: true,
            skipDefault404Handling: true,
            successMessage: "Your password has been changed.",
        })
    }

    public get authenticatedUser(): AuthenticatedUser | undefined {
        return this.authentication?.user;
    }

    public logout(): void {
        this.authentication = null;
        LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);

        if (this.onLogoutCallback) {
            this.onLogoutCallback();
        }
    }

    public setLogoutCallback(callback: (() => void) | null) {
        this.onLogoutCallback = callback;
    }

    public isAuthenticated(): boolean {
        return !!this.authentication && new Date(this.authentication.token.validUntil).getTime() > new Date().getTime();
    }

    public updateAuthenticationField<K extends keyof Authentication>(
        key: K,
        value: Authentication[K]
    ): void {
        if (this.authentication) {
            this.authentication = {
                ...this.authentication,
                [key]: value
            };
            LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
            LocalStorageService.save(LocalStorageKeys.AUTHENTICATION, JSON.stringify(this.authentication));
        }
    }

    private loadAuthenticationFromLocalStorage(): Authentication | null {
        const authenticationStringJson: string | null = LocalStorageService.get(LocalStorageKeys.AUTHENTICATION);
        if (authenticationStringJson) {
            return JSON.parse(authenticationStringJson);
        } else {
            LocalStorageService.remove(LocalStorageKeys.AUTHENTICATION);
            return null;
        }
    }

    private prepareAuthentication(authenticationResponse: AuthenticationResponse): Authentication {
        return {
            user: {
                id: authenticationResponse.user.id,
                email: authenticationResponse.user.email,
                username: authenticationResponse.user.username,
                firstname: authenticationResponse.user.firstname,
                lastname: authenticationResponse.user.lastname,
                bio: authenticationResponse.user.bio,
                authority: authenticationResponse.user.authority as UserAuthority,
                pendingPromotion: authenticationResponse.user.pendingPromotion,
                profileImage: authenticationResponse.user.profileImage,
                chatToken: authenticationResponse.user.chatToken,
                backgroundProfileImage: authenticationResponse.user.backgroundProfileImage,
                ccOnBoarding: authenticationResponse.user.ccOnBoarding,
                registrationStep: authenticationResponse.user.registrationStep,
                fanOnBoarding: authenticationResponse.user.fanOnBoarding,
            },
            token: {
                value: authenticationResponse.token,
                validUntil: this.prepareTokenValidUntil(authenticationResponse.tokenValidInMinutes)
            }
        }
    }

    private prepareTokenValidUntil(tokenValidInMinutes: number): Date {
        return new Date(new Date().getTime() + tokenValidInMinutes * 60 * 1000);
    }
}

export default new AuthService();
