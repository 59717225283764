// StepOne.tsx
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import Uploady from '@rpldy/uploady';
import ProfileBackground from "../../profile/components/profile-background";
import Base64Helper from "../../../utils/base64-helper";
import ProfileImage from "../../profile/components/profile-image";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import AppButton from "../../common/components/app-button";
import TextFormField from "../../common/components/forms/text-form-field";
import {classNames} from "../../../utils/class-names";

interface StepOneProps {
    prevStep: () => void;
    nextStep: () => void;
    step1Ready: boolean;
}

const StepOne: React.FC<StepOneProps> = ({ prevStep, nextStep, step1Ready }) => {
    const { getValues, setValue } = useFormContext();

    return (
        <div>
            <div className="space-y-3">
                <AppButton
                    type="button"
                    label=""
                    className="bg-gray-light text-gray w-10 h-10 !rounded-full"
                    onClick={prevStep}
                >
                    <ChevronLeftIcon className="w-5 min-w-5" />
                </AppButton>
                <h1 className="font-bold text-4xl">Become a creator</h1>
                <span className="text-gray">Fill the fields below to complete your creator profile</span>
            </div>

            <div className="relative top-0 left-0 mb-[100px]">
                <div className="relative inset-0 h-[260px] mt-5 overflow-clip rounded-3xl">
                    <Uploady autoUpload={false}>
                        <ProfileBackground
                            canShowMenuHamburger={false}
                            isEditable={true}
                            className="lg:rounded-3xl md:rounded-none"
                            onChange={(imageSrc) => setValue('backgroundProfileImage', imageSrc, { shouldDirty: true })}
                            backgroundImage={Base64Helper.removeBase64Prefix(getValues('backgroundProfileImage'))}
                        />
                    </Uploady>
                </div>
                <div className="flex justify-center absolute bottom-[-60px] left-1/2 -translate-x-1/2">
                    <Uploady autoUpload={false}>
                        <ProfileImage
                            isEditable={true}
                            avatar={Base64Helper.removeBase64Prefix(getValues('profileImage'))}
                            onChange={(imageSrc) => setValue('profileImage', imageSrc, { shouldDirty: true })}
                            role={UserAuthority.CONTENT_CREATOR}
                            showRole={true}
                        />
                    </Uploady>
                </div>
            </div>

            <div className="flex space-x-4 my-4 justify-center">
                <TextFormField isRequired={true} id="firstName" name="firstName" label="First name" />
                <TextFormField isRequired={true} id="lastName" name="lastName" label="Last name" />
            </div>

            <TextFormField isRequired={true} id="userName" name="userName" label="User name" />

            <div className="my-4">
                <TextFormField id="bio" name="bio" label="Bio" />
            </div>

            <div className={classNames('flex space-x-5 my-6 justify-center items-center')}>
                <div className="bg-white rounded-full w-3 h-3 p-[1px] border-solid border-[3px] border-primary"></div>
                <div className="bg-primary-pastel rounded-full w-1 h-1 p-[1px] border-solid border-2 border-transparent"></div>
                <div className="bg-primary-pastel rounded-full w-1 h-1 p-[1px] border-solid border-2 border-transparent"></div>
                <div className="bg-primary-pastel rounded-full w-1 h-1 p-[1px] border-solid border-2 border-transparent"></div>
            </div>

            <AppButton
                type="button"
                label="Next"
                className="bg-primary text-white w-full !rounded-3xl"
                onClick={nextStep}
                disabled={step1Ready}
            />
        </div>
    );
};

export default StepOne;
