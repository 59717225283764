import {StepOnBoarding} from "../models/interfaces/step-on-boarding.interface";
import directAccessToCelebrities from "@assets/illustrations/direct-access-to-celebrities.png";
import youChoiceTextVoiceAndVideo from "@assets/illustrations/you-choice-text-voice-and-video.png";
import buyUniqueDigitalProductsFromCelebrities from "@assets/illustrations/buy-unique-digital-products-from-celebrities.png";
import subscribeAndGetSpecialFeatures from "@assets/illustrations/subscribe-and-get-special-features.png";


export const fanOnboardingSteps: StepOnBoarding[] = [
    {
        header: 'Direct access to celebrities',
        subHeader: 'Connect with your favorite celebrities',
        description: 'Connect with your favorite celebrities through direct messages on our platform.',
        illustration: directAccessToCelebrities,
    },
    {
        header: 'Your choice: text, voice or video',
        subHeader: 'Send messages in a more personal way',
        description: 'Send messages in a more personal way with our new feature that allows you to send text, voice, or video messages.',
        illustration: youChoiceTextVoiceAndVideo,
    },
    {
        header: 'Subscribe and get special features',
        subHeader: 'Discover the benefits of our premium membership subscription. Unlock exclusive features and content, and take your experience to the next level.',
        description: 'Subscribe now to enjoy all the perks and take full advantage of our platform.',
        illustration: subscribeAndGetSpecialFeatures,
    },
    {
        header: 'Buy unique digital products from celebrities',
        subHeader: 'Explore our wide range of digital products designed to enhance your online experience.',
        description: 'From intuitive user interfaces to seamless integrations, our products are crafted to meet your needs. Whether you\'re a beginner or an expert, our digital solutions will help you achieve your goals with ease.',
        illustration: buyUniqueDigitalProductsFromCelebrities,
    },
];
