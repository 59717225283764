import AppButton from "../../common/components/app-button";
import StickyActionBar from "../../common/components/sticky-action-bar";
import React from "react";
import {useFormDigitalProduct} from "../hooks/use-form-digital-product.hook";

interface CreatorToolsActionsProps {
    parentRef: React.RefObject<any>;
}

const CreatorToolsActions = ({parentRef}: CreatorToolsActionsProps) => {
    const {handleSubmitProduct, mode} = useFormDigitalProduct();

    return (
        <StickyActionBar parentRef={parentRef}>
            <div className='flex gap-2'>
                <AppButton
                    type='submit'
                    onClick={handleSubmitProduct}
                    label={mode === "create" ? 'Add product' : "Edit product"}
                    className='!bg-primary text-white !rounded-2xl w-full hover:brightness-110 focus:brightness-110'
                    disabledClassName='disabled:!bg-gray-200'
                />
                {/*<AppButton label='Preview' className='!rounded-2xl cursor-not-allowed' disabled={true}/>*/}
            </div>
        </StickyActionBar>
    )
}

export default CreatorToolsActions
