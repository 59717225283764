import AppHeader from "../../../layout/app-header";
import React, {useRef} from "react";
import AppBackButton from "../../../layout/components/app-back-button";
import AppContentWrapper from "../../../layout/components/app-content-wrapper";
import ProductForm from "../components/product-form";
import CreatorToolsActions from "../components/creator-tools-actions";
import {FormDigitalProductProvider} from "../contexts/form-digital-product-context";

interface FormDigitalProductViewProps {
    mode: "edit" | "create";
}

const FormDigitalProductView = (props: FormDigitalProductViewProps) => {
    const wrapperRef = useRef(null);
    const {
        mode
    } = props



    return (
        <>
            <AppHeader customContent={true}>
                <div
                    className='flex items-center font-bold text-2xl pt-10 lg:text-[40px] text-primary-darken whitespace-nowrap'>
                    <div className='mr-3 flex'><AppBackButton/></div>
                    {mode === "create" ? "Add product" : "Edit product"}
                </div>
            </AppHeader>
            <FormDigitalProductProvider mode={mode}>
                <AppContentWrapper ref={wrapperRef} className='p-4 bg-white rounded-t-[1.5rem] sm:pt-9 h-full'>
                    <ProductForm/>
                    <CreatorToolsActions parentRef={wrapperRef}/>
                </AppContentWrapper>
            </FormDigitalProductProvider>
        </>
    )
}

export default FormDigitalProductView
