import ApiService from "../../../axios/api.service";
import NotificationService from "../../common/services/notification.service";
import {CreateDigitalProductRequest} from "../models/interfaces/create-digital-product-request.interface";
import {ActiveDigitalProductPreview} from "../models/interfaces/active-digital-product-preview.interface";
import {UpdateDigitalProductRequest} from "../models/interfaces/update-digital-product-request.interface";
import {DigitalProduct} from "../models/interfaces/digital-product.interface";
import {DigitalProductDTO} from "../models/interfaces/digital-product-dto.interface";
import {Pricing} from "../models/interfaces/pricing.interface";
import {ContactType} from "../models/enums/communications-type.enum";


class DigitalProductApiService extends ApiService {

    public async createDigitalProduct(data: CreateDigitalProductRequest, file?: File): Promise<DigitalProductDTO> {
        const formData = new FormData();
        formData.append("request", new Blob([JSON.stringify(data)], {type: "application/json"}));
        if (file) {
            formData.append("file", file, file.name);
        }

        return await this.post<DigitalProductDTO>("/personal-experience", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            successMessage: "Successfully added new product!"
        })
            .catch((error) => {
                NotificationService.error("Error creating digital product");
                throw error;
            });
    }

    public async getOwnerDigitalProducts(userId: string): Promise<DigitalProduct[]> {
        return await this.get<DigitalProduct[]>(`/personal-experience/owner/${userId}`)
            .catch((error) => {
                NotificationService.error("Error loading digital products");
                throw error;
            });
    }

    public async getDigitalProduct(id: number): Promise<DigitalProductDTO> {
        return await this.get<DigitalProductDTO>(`/personal-experience/${id}`)
            .catch((error) => {
                NotificationService.error("Error loading digital product details");
                throw error;
            });
    }

    public async getActiveDigitalProducts(): Promise<ActiveDigitalProductPreview[]> {
        return await this.get<ActiveDigitalProductPreview[]>(`/personal-experience/active`)
            .catch((error) => {
                NotificationService.error("Error loading active digital products");
                throw error;
            });
    }

    public async updateDigitalProduct(id: number, data: UpdateDigitalProductRequest, file?: File): Promise<void> {
        const formData = new FormData();
        formData.append("request", new Blob([JSON.stringify(data)], {type: "application/json"}));
        if (file) {
            formData.append("file", file, file.name);
        }

        return await this.put<void>(`/personal-experience/${id}`, formData, {
            successMessage: "Product updated successfully",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).catch((error) => {
            NotificationService.error("Error updating digital product");
            throw error;
        });
    }

    public async deleteDigitalProduct(id: number): Promise<void> {
        return await this.delete<void>(`/personal-experience/${id}`, {
            successMessage: "Digital product deleted successfully",
        }).catch((error) => {
            NotificationService.error("Error deleting digital product");
            throw error;
        });
    }

    public async buyDigitalProduct(productId: number): Promise<void> {
        return await this.post<void>(`/personal-experience/buy/${productId}`, {}, {
            successMessage: "Purchase completed successfully! Your digital product is now available.",
        }).catch((error) => {
            NotificationService.error("Error purchasing digital product");
            throw error;
        });
    }

    public async getContactPrices(): Promise<Pricing> {
        return await this.get<Pricing>(`/profile/pricing`)
            .catch((error) => {
                NotificationService.error("Error loading pricing");
                throw error;
            });
    }

    public async changeContactPrice(yCredits: number, typeContact: ContactType): Promise<void> {
        switch (typeContact) {
            case ContactType.CALL:
                return await this.put<void>(`/profile/call-price/${yCredits}`, {}, {
                    skipDefault404Handling: true,
                })
                    .catch((error) => {
                        NotificationService.error("Error updating call price");
                        throw error;
                    });
            case ContactType.CHAT:
                return await this.put<void>(`/profile/chat-price/${yCredits}`, {}, {
                    skipDefault404Handling: true,
                })
                    .catch((error) => {
                        NotificationService.error("Error updating chat price");
                        throw error;
                    });
            case ContactType.VIDEO_CALL:
                return await this.put<void>(`/profile/video-call-price/${yCredits}`, {}, {
                    skipDefault404Handling: true,
                })
                    .catch((error) => {
                        NotificationService.error("Error updating video call price");
                        throw error;
                    });
            default:
                console.error("Not unknow contact type.", typeContact)
        }

    }

}

export default new DigitalProductApiService();
