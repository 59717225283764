import ApiService from "../../../axios/api.service";
import {DictionaryEntry} from "../models/interfaces/dictionary-entry.interface";

class DictionaryService extends ApiService {

    public async getGenders(): Promise<DictionaryEntry[]> {
        return await this.get<DictionaryEntry[]>(`/users/dictionary/gender`, {});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DictionaryService()


