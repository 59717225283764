import {NavLink} from 'react-router-dom';
import SubmenuTab from "../../core/components/submenu-tab";
import {WalletSubmenu} from "../constants/wallet-submenu";

const WalletMenu = () => {
    const baseLocation = "/wallet";

    return (
        <div className='overflow-y-clip overflow-x-auto flex gap-2 cursor-pointer'>
            {WalletSubmenu.map(item => (
                <NavLink
                    key={item.href}
                    to={baseLocation + item.href}
                    end={true}
                >
                    {({isActive}) => (
                        <SubmenuTab
                            key={item.href}
                            text={item.text}
                            isActive={isActive}
                        />
                    )}
                </NavLink>
            ))}
        </div>
    );
}

export default WalletMenu;
