import React, {useLayoutEffect, useRef, useState} from 'react';
import {useMediaQuery} from "react-responsive";
import {animated, useTransition} from "@react-spring/web";

interface StickyActionBarProps {
    children: React.ReactNode;
    parentRef?: React.RefObject<HTMLDivElement>;
    forceReload?: boolean;
}

const StickyActionBar: React.FC<StickyActionBarProps> = ({children, parentRef, forceReload}) => {
    const [position, setPosition] = useState({x: 0});
    const actionBarRef = useRef<HTMLDivElement>(null);
    const isDesktop = useMediaQuery({minWidth: 768});

    const updatePosition = () => {
        if (parentRef?.current && actionBarRef.current) {
            const parentRect = parentRef.current.getBoundingClientRect();
            const windowWidth = window.innerWidth;
            const newX = parentRect.left + (parentRect.width / 2) - (actionBarRef.current.offsetWidth / 2);
            const adjustedX = Math.max(0, Math.min(newX, windowWidth - actionBarRef.current.offsetWidth));

            setPosition({x: adjustedX});
        }
    };

    useLayoutEffect(() => {
        window.addEventListener('resize', updatePosition);
        window.addEventListener('scroll', updatePosition);

        setTimeout(() => {
            updatePosition();
        }, 40);

        return () => {
            window.removeEventListener('resize', updatePosition);
            window.removeEventListener('scroll', updatePosition);
        };
    }, [parentRef]);

    const transitions = useTransition(true, {
        delay: 300,
        from: {transform: 'translateY(calc(120%))'},
        enter: {transform: 'translateY(0%)'},
        leave: {transform: 'translateY(100%)'},
        config: {tension: 220, friction: 20},
    });

    useLayoutEffect(() => {
        if (parentRef?.current) {
            const resizeObserver = new ResizeObserver(() => {
                updatePosition();
            });

            resizeObserver.observe(parentRef.current);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [parentRef]);

    useLayoutEffect(() => {
        updatePosition();
    }, [forceReload]);

    return (
        <>
            {/* Faktyczny komponent, który jest animowany */}
            {transitions((style: any, item: any) =>
                    item && (
                        <animated.div
                            ref={actionBarRef}
                            style={{
                                boxShadow: isDesktop ?'0px 0px 8px -5px rgb(66, 68, 90) ' :'10px 10px 16px 3px rgba(66, 68, 90, 1)',
                                left: isDesktop ? `${position.x}px` : '0',
                                transform: isDesktop ? 'translateX(0)' : '',
                                ...style,
                            }}
                            className={`z-[2] bg-white md:bg-gray-light w-full max-w-full md:rounded-3xl md:max-w-fit md:block fixed bottom-0 md:bottom-[20px]`}
                        >
                            <div className='p-5 md:p-4'>
                                {children}
                            </div>
                        </animated.div>
                    )
            )}
        </>
    );
};

export default StickyActionBar;
