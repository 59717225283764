import {useState} from 'react';
import DigitalProductApiService from "../services/digital-product-api.service";
import {DigitalProduct} from "../models/interfaces/digital-product.interface";
import {ContactType} from "../models/enums/communications-type.enum";
import {DigitalProductDTO} from "../models/interfaces/digital-product-dto.interface";
import {ActiveDigitalProductPreview} from "../models/interfaces/active-digital-product-preview.interface";
import {CreateDigitalProductRequest} from "../models/interfaces/create-digital-product-request.interface";
import {Pricing} from "../models/interfaces/pricing.interface";
import {DigitalProductFormBody} from "../../digital-products/models/interfaces/digital-product-form-body.interface";

export const useDigitalProductApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getOwnerDigitalProducts = async (userId: string): Promise<DigitalProduct[]> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.getOwnerDigitalProducts(userId);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const getDigitalProduct = async (id: number): Promise<DigitalProductDTO> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.getDigitalProduct(id);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const getActiveDigitalProducts = async (): Promise<ActiveDigitalProductPreview[]> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.getActiveDigitalProducts();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const createDigitalProduct = async (data: DigitalProductFormBody): Promise<DigitalProductDTO> => {
        setLoading(true);
        setError(null);
        try {
            const mappedBody: CreateDigitalProductRequest = {
                activityTime: data.activityTime!,
                content: data.content!,
                deliveryTimeInDays: data.deliveryTimeInDays!,
                deliveryType: data.deliveryType!,
                description: data.description!,
                price: data.price,
                title: data.title!,
                type: data.type
            }
            const file: File | undefined = data.files?.[0];
            return await DigitalProductApiService.createDigitalProduct(mappedBody, file);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const updateDigitalProduct = async (id: number, data: DigitalProductFormBody): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            const mappedBody: CreateDigitalProductRequest = {
                activityTime: data.activityTime!,
                content: data.content!,
                deliveryTimeInDays: data.deliveryTimeInDays!,
                deliveryType: data.deliveryType!,
                description: data.description!,
                price: data.price,
                title: data.title!,
                type: data.type
            }
            let file  :File | undefined = undefined;
            if (data.files?.length && data.files[0].size > 0){
                file = data.files[0];
            }
            return await DigitalProductApiService.updateDigitalProduct(id, mappedBody,file);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const deleteDigitalProduct = async (id: number): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.deleteDigitalProduct(id);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const buyDigitalProduct = async (productId: number): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.buyDigitalProduct(productId);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const getContactPrices = async (): Promise<Pricing> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.getContactPrices();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const changeContactPrice = async (yCredits: number, typeContact: ContactType): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.changeContactPrice(yCredits, typeContact);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        error,
        getOwnerDigitalProducts,
        getDigitalProduct,
        getActiveDigitalProducts,
        createDigitalProduct,
        updateDigitalProduct,
        deleteDigitalProduct,
        buyDigitalProduct,
        getContactPrices,
        changeContactPrice,
    };
};
