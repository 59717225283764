import {NavigationProfileAnchors} from "../types/navigation-profile-anchors.interface";

export const NavigationCCProfile = [
    {
        name: 'Contact pricing',
        href: NavigationProfileAnchors.CONTACT_PRICING,
        strictActive: false,
    },
    // {
    //     name: 'Subscription',
    //     href: '#subscription',
    //     strictActive: true,
    // },
    {
        name: 'Digital products',
        href: NavigationProfileAnchors.DIGITAL_PRODUCTS,
        strictActive: true,
    },
    {
        name: 'Bio',
        href: NavigationProfileAnchors.BIO,
        strictActive: true,
    },
    // {
    //     name: 'Social media',
    //     href: '#social-media',
    //     strictActive: true,
    // },
]

export const NavigationFanProfile = [
    {
        name: 'Edit profile',
        strictActive: true,
        link: './'
    },
    {
        name: 'Become a creator',
        strictActive: false,
        link: '/become-creator'
    },
]
