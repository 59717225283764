import React, {useContext} from "react";
import WrapperCard from "../../common/components/wrapper-card";
import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import Coin from "../../core/components/coin";
import {PencilSquareIcon, PhotoIcon, TrashIcon} from "@heroicons/react/24/outline";
import DigitalProductCategoryService from "../services/digital-product-category.service";
import {ReactComponent as PinFilledIcon} from "@assets/icons/pin-filled.svg";
import {classNames} from "../../../utils/class-names";
import AppButton from "../../common/components/app-button";
import {SessionCreatorToolsContext} from "../contexts/session-creator-tools-context";
import {useNavigate} from "react-router-dom";

interface CardDigitalProductProps {
    product: DigitalProduct;
    isPinned?: boolean;
    showEdit?: boolean;
}

const CardDigitalProduct = (props: CardDigitalProductProps) => {
    const {
        product,
        isPinned = false,
        showEdit = true,
    } = props;
    const {deleteProduct} = useContext(SessionCreatorToolsContext)!;
    // const navigate = useNavigate();
    const {type, title, price} = product;
    const navigate = useNavigate();
    const goToEditProductHandler = (productId: number) => navigate(`../edit-product/${productId}`)
    // const handleClick = () => navigate(`../digital-product/${product.id}`)
    const handleClick = () => {

    }

    return (
        <WrapperCard
            onClick={handleClick}
            className={classNames(
                "w-full flex justify-between gap-3 !p-[7px] border rounded-2xl shadow-sm relative overflow-ellipsis",
                isPinned && "!border-black"
            )}>
            {isPinned && (
                <div
                    className="absolute top-[-1px] right-[-1px] bg-black rounded-bl-xl rounded-tr-xl p-2 cursor-pointer">
                    <PinFilledIcon className="w-[10px] h-[10px] text-white"/>
                </div>
            )}
            <div className='flex items-center gap-3 truncate'>
                <div
                    className='min-w-[72px] w-[72px] h-[72px] flex-grow border border-special-gray rounded-2xl bg-gray-light flex justify-center items-center'>
                    <PhotoIcon className='w-5 h-5 text-gray'/>
                </div>

                <div className="flex flex-col justify-center truncate">
                    <span className="text-primary font-semibold text-[0.625rem] uppercase tracking-wide truncate">
                        {DigitalProductCategoryService.getCategoryName(type)}
                    </span>
                    <h3 className="font-semibold text-base max-w-full truncate">{title}</h3>
                    <div className="flex items-center gap-1 text-lg font-bold text-gray-700">
                        {price}
                        <Coin/>
                    </div>
                </div>
            </div>
            <div className='flex flex-col justify-between gap-2'>
                <AppButton
                    onClick={() => deleteProduct(product.id)}
                    label=''
                    className='!p-2 hover:text-primary focus:text-primary'>
                    <div><TrashIcon className='w-4 h-4'/></div>
                </AppButton>
                {showEdit && (
                    <AppButton
                        onClick={() => goToEditProductHandler(product.id)}
                        label=''
                        className='!p-2 hover:text-primary focus:text-primary'>
                        <div><PencilSquareIcon className='w-4 h-4'/></div>
                    </AppButton>
                )}
            </div>
        </WrapperCard>
    );
};

export default CardDigitalProduct;
