import {useContext} from "react";
import {WalletContext} from "../../contexts/wallet-context";
import OfferPriceCard from "../offer-price-card";

const OffersBuyCredits = () => {
    const {prices} = useContext(WalletContext)!;

    return (
        <div className="space-y-2">
            {prices.map((price) => (
                <OfferPriceCard key={price.id} price={price}/>
            ))}
        </div>
    )
}

export default OffersBuyCredits
