import React, {createContext} from 'react';
import {ComponentType} from "../../common/models/types/component.type";
import {ProfileUserForm} from "../../core/models/interfaces/profile-form-data.interface";
import useAuth from "../../auth/hooks/use-auth";
import * as yup from 'yup';
import {useForm, UseFormReturn} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useUserProfileApi} from "../../core/hooks/use-user-profile-api";
import {AuthenticatedUser} from "../../auth/models/interfaces/authenticated-user.interface";
import Base64Helper from "../../../utils/base64-helper";

interface ProfileFormContextType {
    methods: UseFormReturn<ProfileUserForm>,
    handleUpdateProfile: () => Promise<void>,
}

export const ProfileFormContext = createContext<ProfileFormContextType | undefined>(undefined);

const profileFormSchema = yup.object({
    firstname: yup.string().required('Firstname is required'),
    lastname: yup.string().required('Lastname is required'),
    username: yup.string().required('Username is required'),
    bio: yup.string().optional(),
    profileImage: yup.string().optional(),
    backgroundProfileImage: yup.string().optional()
}).required();

export const ProfileFormProvider: React.FC<{
    children: ComponentType
}> = ({children}) => {
    const {currentUser, updateAuthenticationField} = useAuth();
    const {updateProfile} = useUserProfileApi();
    const methods = useForm<ProfileUserForm>({
        defaultValues: {
            profileImage: currentUser?.profileImage + '',
            backgroundProfileImage: currentUser?.backgroundProfileImage + '',
            firstname: currentUser?.firstname || '',
            lastname: currentUser?.lastname || '',
            username: currentUser?.username || '',
            bio: currentUser?.bio || '',
        },
        resolver: yupResolver(profileFormSchema),
        mode: 'onSubmit',
    });

    const handleUpdateProfile = async (): Promise<void> => {
        return await methods.handleSubmit(
            async (data) => {
                const sanitizedData = {
                    ...data,
                    profileImage: data.profileImage === 'null' ? null : data.profileImage,
                    backgroundProfileImage: data.backgroundProfileImage === 'null' ? null : data.backgroundProfileImage,
                };
                await updateProfile(sanitizedData as ProfileUserForm)
                    .then(async () => {
                        const updatedUserAuthentication: AuthenticatedUser = {
                            ...currentUser!,
                            ...sanitizedData!,
                            backgroundProfileImage: Base64Helper.removeBase64Prefix(sanitizedData.backgroundProfileImage ?? ''),
                            profileImage: Base64Helper.removeBase64Prefix(sanitizedData.profileImage ?? ''),
                        }
                        updateAuthenticationField('user', updatedUserAuthentication)
                        methods.reset(sanitizedData as ProfileUserForm)
                    })
            }
        )();
    };

    return (
        <ProfileFormContext.Provider value={{methods, handleUpdateProfile}}>
            {children}
        </ProfileFormContext.Provider>
    );
};
