// StepTwo.tsx
import React from 'react';
import { ChevronLeftIcon, ChatBubbleLeftRightIcon, InformationCircleIcon, SpeakerWaveIcon, VideoCameraIcon } from "@heroicons/react/24/outline";
import {classNames} from "../../../utils/class-names";
import AppButton from "../../common/components/app-button";
import SwitcherFormField from "../../common/components/forms/switcher-form-field";
import NumberFormField from "../../common/components/forms/number-form-field";

interface StepTwoProps {
    prevStep: () => void;
    nextStep: () => void;
    messageDisabled: boolean;
    voiceDisabled: boolean;
    videoDisabled: boolean;
}

const StepTwo: React.FC<StepTwoProps> = ({ prevStep, nextStep, messageDisabled, voiceDisabled, videoDisabled }) => (
    <>
        <div className={classNames('space-y-3')}>
            <AppButton
                type="button"
                label=""
                className="bg-gray-light text-special-gray w-10 h-10 !rounded-full"
                onClick={prevStep}
            >
                <ChevronLeftIcon className="w-5 min-w-5" />
            </AppButton>
            <h1 className="font-bold text-4xl">Set your contact pricing</h1>
            <div className="text-gray">Lorem Ipsum is simply dummy text of the printing and typesetting industry</div>
        </div>

        <div className="flex justify-start space-x-2 mt-5 mb-5">
            <ChatBubbleLeftRightIcon className="w-5 h-5" />
            <div>Text messages</div>
            <SwitcherFormField className="float-right" name="isTextMessages" />
        </div>

        <NumberFormField
            min={0}
            showArrows={true}
            disabled={messageDisabled}
            name="textMessagePrice"
            label="One message block price"
        />
        <div className="text-color-gray-500 text-xs text-gray flex justify-start">
            <InformationCircleIcon className="w-4 h-4" />
            <div>You'll earn $4.00 for every message block</div>
        </div>

        <div className="flex justify-start space-x-2 mt-5 mb-5">
            <SpeakerWaveIcon className="w-5 h-5" />
            <div>Voice messages</div>
            <SwitcherFormField className="float-right" name="isVoiceMessages" />
        </div>

        <NumberFormField
            min={0}
            showArrows={true}
            disabled={voiceDisabled}
            name="voiceMessagePrice"
            label="One minute price"
        />

        <div className="text-color-gray-500 text-xs text-gray flex justify-start">
            <InformationCircleIcon className="w-4 h-4" />
            <div>You'll earn $8.00 for every minute</div>
        </div>

        <div className="flex justify-start space-x-2 mt-5 mb-5">
            <VideoCameraIcon className="w-5 h-5" />
            <div>Video messages</div>
            <SwitcherFormField className="float-right" name="isVideoMessages" />
        </div>

        <NumberFormField
            min={0}
            showArrows={true}
            disabled={videoDisabled}
            name="videoMessagePrice"
            label="One minute price"
        />

        <div className="text-color-gray-500 text-xs text-gray flex justify-start">
            <InformationCircleIcon className="w-4 h-4" />
            <div>You'll earn $12.00 for every minute</div>
        </div>

        <div className={classNames('flex space-x-5 my-6 justify-center items-center')}>
            <div className="bg-primary-pastel rounded-full w-1 h-1 p-[1px] border-solid border-2 border-transparent"></div>
            <div className="bg-white rounded-full w-3 h-3 p-[1px] border-solid border-[3px] border-primary"></div>
            <div className="bg-primary-pastel rounded-full w-1 h-1 p-[1px] border-solid border-2 border-transparent"></div>
            <div className="bg-primary-pastel rounded-full w-1 h-1 p-[1px] border-solid border-2 border-transparent"></div>
        </div>

        <AppButton
            type="button"
            label="Next"
            className="bg-primary text-white w-full !rounded-3xl"
            onClick={nextStep}
        />
    </>
);

export default StepTwo;
