import React, {useEffect, useState} from 'react';
import {Base64Prefix} from "../../common/constats/base64-prefix";
import LabelUserRole from "../../core/components/label-user-role";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import AppButton from "../../common/components/app-button";
import {ArrowUturnLeftIcon, PencilIcon} from "@heroicons/react/24/outline";
import DefaultProfileImg from "../../common/components/default-profile-img";
import {useBatchAddListener} from "@rpldy/uploady";
import UploadButton from "@rpldy/upload-button";
import SpinnerLoader from "../../common/components/spinner-loader";

interface ProfileImageProps {
    avatar?: string;
    showRole?: boolean;
    role: UserAuthority;
    isEditable?: boolean;
    onChange?: (imageSrc: string) => void;
}

const ProfileImage: React.FC<ProfileImageProps> = (props: ProfileImageProps) => {
    const [loading, setLoading] = useState(false);
    const [originalAvatar, __] = useState<string | undefined>(props.avatar ?? '');
    const [tempAvatar, setTempAvatar] = useState<string>('');
    const {
        avatar,
        showRole = true,
        role,
        isEditable = false,
        onChange
    } = props;

    const handleImageUpload = (file: File) => {
        setLoading(true);
        const reader = new FileReader();

        reader.onloadend = () => {
            setTempAvatar(reader.result as string);
            onChange && onChange(reader.result as string);
            setLoading(false);
        };

        reader.readAsDataURL(file);
    };

    const handleCancel = () => {
        setTempAvatar('');
        onChange && onChange(originalAvatar ?? '');
    };

    if (isEditable) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useBatchAddListener((batch) => {
            if (batch) {
                handleImageUpload(batch.items[0].file as File);
            }
        });
    }

    useEffect(() => {
        if (tempAvatar) setTempAvatar('');
    }, [props.avatar])

    return (
        <div className="w-32 h-32 relative">
            <div className='w-full h-full overflow-hidden rounded-3xl'>
                {tempAvatar ? (
                    <img
                        src={tempAvatar}
                        alt="Avatar"
                        className="w-full h-full object-cover rounded-3xl border-[5px] border-white"
                    />
                ) : avatar ? (
                    <img
                        src={`${Base64Prefix}${avatar}`}
                        alt="Avatar"
                        className="w-full h-full object-cover rounded-3xl border-[5px] border-white"
                    />
                ) : (
                    <div className="w-full h-full object-cover rounded-3xl border-[5px] border-white overflow-hidden">
                        <DefaultProfileImg/>
                    </div>
                )}

                {loading && (
                    <div
                        className="absolute inset-0 flex justify-center rounded-3xl items-center bg-gray-700 bg-opacity-50">
                        <SpinnerLoader color='var(--primary)' size='16px'/>
                    </div>
                )}
            </div>

            {showRole && (
                <div className='relative bottom-[20px] left-1/2 translate-x-[-50%] h-fit w-fit'>
                    <LabelUserRole role={role}/>
                </div>
            )}

            {isEditable && (
                <div className="absolute top-0 right-0 flex space-x-2">
                    {tempAvatar ? (
                        <AppButton
                            label=''
                            onClick={handleCancel}
                            className='relative top-[-7px] right-[-7px] text-white bg-black rounded-xl w-8 h-8 p-1 border-0'>
                            <ArrowUturnLeftIcon className='w-4' strokeWidth={2}/>
                        </AppButton>
                    ) : (
                        <UploadButton
                            className='relative top-[-7px] right-[-7px] flex justify-center items-center text-white bg-black rounded-xl w-8 h-8 p-1 border-0'>
                            <PencilIcon className='w-4' strokeWidth={2}/>
                        </UploadButton>
                    )}
                </div>
            )}
        </div>
    );
};

export default ProfileImage;
