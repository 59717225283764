import { ChannelList, DefaultStreamChatGenerics, useChatContext } from "stream-chat-react";
import useAuth from "../../../auth/hooks/use-auth";
import { ChannelFilters, ChannelOptions, ChannelSort } from "stream-chat";
import React, { useEffect, useState } from 'react';
import CustomChatItem from "../custom-chat-item";
import { useLocation } from 'react-router-dom';
import { useActiveChat } from "../../hooks/use-active-chat";
import AppHeader from "../../../../layout/app-header";
import AppHamburgerMenu from "../../../../layout/components/app-hamburger-menu";
import Breadcrumbs from "../../../../layout/components/breadcrumbs";
import SubmenuTab from "../../../core/components/submenu-tab";
import AppContentWrapper from "../../../../layout/components/app-content-wrapper";
import StreamChatService from "../../services/stream-chat.service";

const ChatList = () => {
    const { currentUser } = useAuth();
    const { client } = useChatContext();
    const { setActiveChannel } = useActiveChat();
    const location = useLocation();
    const channelId = location.state?.channelId;
    const [isClientReady, setIsClientReady] = useState(false);

    const filters: ChannelFilters<DefaultStreamChatGenerics> = {
        type: 'messaging',
        members: { $in: [currentUser!.id ?? ''] },
        last_message_at: { $exists: true }
    };

    const sort: ChannelSort<DefaultStreamChatGenerics> = {
        last_message_at: -1
    };

    const options: ChannelOptions = {
        state: true,
        presence: true,
        watch: true,
    };

    useEffect(() => {
        StreamChatService.onClientReady(() => setIsClientReady(true));
    }, []);

    useEffect(() => {
        const setChannelById = async () => {
            if (channelId && client?.userID) {
                const channel = client.channel('messaging', channelId);
                await channel.watch();
                setActiveChannel(channel);
            }
        };

        const fetchChannels = async () => {
            try {
                await StreamChatService.connectUser(currentUser!)
                await StreamChatService.getChannels(filters, sort, options);
                await setChannelById();
            } catch (error) {
                console.error("Error fetching channels:", error);
            }
        };

        if (isClientReady) {
            fetchChannels();
        }
    }, [channelId, isClientReady]);

    return (
        <div className='flex flex-col max-w-full min-w-[300px] w-full md:max-w-[400px] h-full flex-shrink-[2]'>
            <AppHeader customContent={true} className='!p-0' classNameBgDesktop='md:rounded-3xl h-full'>
                <div className='flex justify-between flex-wrap gap-5'>
                    <div className='flex flex-col gap-5'>
                        <div className='flex items-center w-fit'>
                            <div className='mr-3 lg:hidden'><AppHamburgerMenu/></div>
                            <Breadcrumbs path={location.pathname}/>
                        </div>
                        <SubmenuTab text={'All messages'} isActive={true}/>
                    </div>
                </div>
            </AppHeader>
            <AppContentWrapper className='pt-4 bg-white rounded-t-[1.5rem]'>
                <div className='w-full relative'>
                    {isClientReady && (
                        <ChannelList
                            filters={filters}
                            sort={sort}
                            showChannelSearch={true}
                            options={options}
                            Preview={(props) => (
                                <CustomChatItem
                                    channel={props.channel}
                                    key={props.channel.id}
                                    onClick={() => setActiveChannel(props.channel)}
                                />
                            )}
                        />
                    )}
                </div>
            </AppContentWrapper>
        </div>
    );
};

export default ChatList;
