import {ReactNode} from "react";


type AppWrapperProps = {
    children: ReactNode;
    className?: string;
};

const AppWrapper = (props: AppWrapperProps) => {
    const {children, className} = props
    return (
        <div className={'mx-auto relative overflow-x-clip max-h-lvh min-h-svh '+className}>
            {children}
        </div>
    )
}

export default AppWrapper
