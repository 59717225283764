import React, {createContext, useContext, useEffect, useState} from 'react';
import {useForm, UseFormReturn} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaAddProduct} from "../constants/schema-add-product";
import {DigitalProductFormBody} from "../models/interfaces/digital-product-form-body.interface";
import {DigitalProductType} from "../../core/models/enums/digital-product-types.enum";
import {SessionCreatorToolsContext} from "./session-creator-tools-context";
import {useParams} from "react-router-dom";
import {useDigitalProductApi} from "../../core/hooks/use-digital-product-api";

interface FormDigitalProductContextType {
    methods: UseFormReturn<DigitalProductFormBody>;
    handleSubmitProduct: () => Promise<void>;
    mode: "create" | "edit" | "preview";
    setMode: (mode: "create" | "edit" | "preview") => void;
    loading: boolean;
}

interface FormDigitalProductProviderProps {
    children: React.ReactNode,
    mode: "create" | "edit" | "preview"
}

export const FormDigitalProductContext = createContext<FormDigitalProductContextType | undefined>(undefined);

export const FormDigitalProductProvider: React.FC<FormDigitalProductProviderProps> = (props: FormDigitalProductProviderProps) => {
    const {addNewProduct, editProduct} = useContext(SessionCreatorToolsContext)!;
    const {getDigitalProduct, loading} = useDigitalProductApi();
    const [mode, setMode] = useState(props.mode);
    const [editedProductId, setEditedProductId] = useState<number | null>(null);
    const params = useParams();
    const {children} = props
    const methods = useForm<DigitalProductFormBody>({
        defaultValues: {
            type: DigitalProductType.PRIVATE_CHAT,
            title: "",
            description: "",
            activityTime: null,
            files: [],
            deliveryType: null,
            price: 1,
        },
        // @ts-ignore
        resolver: yupResolver(schemaAddProduct),
        mode: 'onSubmit',
    });

    const handleSubmitProduct = async () => {
        console.log("Submitting product...");
        console.log(methods.formState.isValid)
        await methods.handleSubmit(async (data) => {
            console.log("Submitted data:", data);
            try {
                if (mode === "create") {
                    await addNewProduct(data);
                    methods.reset();
                } else if (mode === "edit" && editedProductId) {
                    await editProduct(editedProductId, data);
                }
            } catch (error) {
                console.error("Error while submitting product:", error);
            }
        })();
    };


    useEffect(() => {
        if (mode === "edit") {
            const productId = params["productId"];
            productId && setEditedProductId(parseInt(productId))
        }
    }, [])

    useEffect(() => {
        const fetchProduct = async (editedProductId: number) => {
            try {
                const product = await getDigitalProduct(editedProductId);
                const formData = {
                    type: product.type,
                    title: product.title,
                    price: product.price,
                    activityTime: product.activityTime,
                    description: product.description,
                    content: product.content,
                    deliveryTimeInDays: product.deliveryTimeInDays,
                    deliveryType: product.delivery,
                    files: [],
                };
                methods.reset(formData);
                if (product.type === "FILE"){
                    methods.setValue("files", [new File([], product.fileName || "notLoadedFile.txt")]);
                }
            } catch (error) {
                console.error("Błąd podczas pobierania produktu:", error);
            }
        };

        if (editedProductId) {
            fetchProduct(editedProductId);
        }
    }, [editedProductId, methods]);

    return (
        <FormDigitalProductContext.Provider value={{methods, handleSubmitProduct, mode, setMode, loading}}>
            {children}
        </FormDigitalProductContext.Provider>
    );
};
