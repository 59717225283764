import React, {RefObject, useEffect, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {Listbox} from '@headlessui/react';
import {ChevronDownIcon} from "@heroicons/react/24/solid";
import {classNames} from "../../../../utils/class-names";
import SkeletonFormField from "./skeleton-form-field";

interface SelectFormFieldProps {
    name: string;
    label: string;
    options?: any[];
    fetchOptions?: () => Promise<any[]>;
    className?: string;
    valueKey?: string;
    labelKey?: string;
    parentWrapperRef?: RefObject<HTMLElement>;
    onChange?: (value: any) => void;
    isLoading?: boolean;
    isRequired?: boolean;
}

const SelectFormField: React.FC<SelectFormFieldProps> = (props: SelectFormFieldProps) => {
    const {register, setValue, watch, formState: {errors, isSubmitting, disabled}} = useFormContext();
    const [dropUp, setDropUp] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(props.isLoading || false);
    const [options, setOptions] = useState<any[]>(props.options || []);
    const [selectedOption, setSelectedOption] = useState<any | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);
    const {
        name,
        label,
        className = '',
        valueKey = 'value',
        labelKey = 'label',
        isRequired = false,
        parentWrapperRef,
        onChange,
        fetchOptions,
    } = props;
    const currentValue = watch(name);


    useEffect(() => {
        const loadOptions = async () => {
            if (fetchOptions) {
                setIsLoading(true);
                try {
                    const fetchedOptions = await fetchOptions();
                    setOptions(fetchedOptions);
                } catch (error) {
                    console.error("Failed to fetch options:", error);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        loadOptions();
    }, []);

    useEffect(() => {
        if (currentValue !== undefined && options.length > 0) {
            const initialOption = options.find(option => option[valueKey] === currentValue);
            setSelectedOption(initialOption || null);
            setValue(name, initialOption?.[valueKey] || '');
        }
    }, [currentValue]);

    useEffect(() => {
        setIsLoading(!!props.isLoading)
    }, [props.isLoading]);

    const checkDropUp = () => {
        if (parentWrapperRef?.current && selectRef.current) {
            const optionHeight: number = 42;
            const optionsHeight: number = optionHeight * options.length;
            const parentRect: DOMRect = parentWrapperRef.current.getBoundingClientRect();
            const selectRect: DOMRect = selectRef.current.getBoundingClientRect();
            const spaceBelow: number = parentRect.bottom - selectRect.bottom;
            const shouldDropUp: boolean = spaceBelow - optionsHeight < 0;
            setDropUp(shouldDropUp);
        }
    };

    const handleChange = (option: any): void => {
        setSelectedOption(option);
        setValue(name, option[valueKey]);
        onChange && onChange(option);
        setIsOpen(false);
    };

    return (
        <div className="relative w-full h-fit" ref={selectRef}>
            <label htmlFor={name} className="absolute block text-sm font-medium top-2 left-[14px]">
                {label}
                {isRequired && <span className='text-red-500'>*</span>}
            </label>

            {isLoading ? (
                <SkeletonFormField/>
            ) : (
                <Listbox value={selectedOption} onChange={handleChange} disabled={isSubmitting}>
                    <Listbox.Button
                        onClick={() => {
                            checkDropUp();
                            setIsOpen(!isOpen);
                        }}
                        className={classNames(
                            'relative border-2 rounded-xl px-3 py-2 pt-6 appearance-none w-full focus:outline-none focus:ring-2',
                            errors[name] ? 'border-red-500 focus:ring-red-500' : isOpen ? 'border-primary focus:ring-primary' : 'border-gray-light focus:ring-primary',
                            className,
                            disabled && "!bg-gray-light text-gray"
                        )}
                    >
                        <span className={classNames(
                            "block truncate text-left text-[#9ca3afe8]",
                            selectedOption && '!text-black'
                        )}>{selectedOption?.[labelKey] || 'Select an option'}</span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>
                        </span>
                    </Listbox.Button>
                    <Listbox.Options
                        className={classNames(
                            dropUp ? 'bottom-full mb-2 ' : 'top-full mt-1',
                            'absolute w-full py-1 mt-1 bg-white border-2 rounded-xl focus:outline-none z-10',
                            isOpen ? 'border-primary ring-primary' : 'border-gray-light'
                        )}
                    >
                        {options.map((option, index) => (
                            <Listbox.Option
                                key={index}
                                value={option}
                                className={({active}) => classNames(
                                    'cursor-pointer select-none relative py-2 mx-4',
                                    active ? 'bg-primary-600 text-primary' : '',
                                    options.length > index && 'border-b border-gray-light'
                                )}
                            >
                                {({selected}) => (
                                    <span className={classNames(
                                        'block truncate',
                                        selected ? 'font-medium' : 'font-normal'
                                    )}>
                                        {option[labelKey]}
                                    </span>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Listbox>
            )}

            <input type="hidden" {...register(name)} value={selectedOption?.[valueKey] || ''}/>

            {errors[name] && <p className="text-red-500 text-xs">{'' + errors[name]?.message}</p>}
        </div>
    );
};

export default SelectFormField;
