import {ReactComponent as CellPhoneIcon} from "@assets/icons/cell-phone.svg";
import AppButton from "../../common/components/app-button";
import {usePwaInstallFlow} from "../../common/hooks/use-pwa-install-flow";
import {useEffect, useState} from "react";
import {XMarkIcon} from "@heroicons/react/16/solid";

const PromptInstallPwa = () => {
    const [showPrompt, setShowPrompt] = useState(true);
    const {installPWA, isInstalled} = usePwaInstallFlow();

    const handleClosePrompt = () => setShowPrompt(false);

    useEffect(() => {

    }, [isInstalled]);

    if (isInstalled || !showPrompt) return null;

    return (
        <div className='absolute bottom-[15px] left-1/2 -translate-x-1/2 w-full px-5 overflow-clip py-1'>
            <div
                className='max-w-[800px] w-full bg-gray-light flex flex-wrap justify-between gap-3 flex-grow rounded-2xl
                p-3 shadow-md fade-slide-up opacity-0'>
                <header className='flex gap-2'>
                    <CellPhoneIcon
                        className='min-w-10 w-10 h-10 p-1 text-primary-darken inline-block bg-primary-pastel rounded-xl'/>
                    <div className='flex flex-col'>
                        <h3 className='font-semibold text-base leading-5'>Get better experience</h3>
                        <p className='text-gray text-sm'>Get 20 yCredits for your first top-up</p>
                    </div>
                </header>
                <div className='flex justify-end items-center space-x-3'>
                    <AppButton
                        onClick={installPWA}
                        label='Download app'
                        className='bg-primary-pastel text-primary !rounded-2xl font-semibold text-sm py-[9px] transition
                     hover:text-white hover:bg-primary focus:text-white focus:bg-primary'/>
                    <AppButton
                        onClick={handleClosePrompt}
                        label=''
                        className='!p-[7px] bg-white !rounded-full text-special-gray hover:text-primary focus:text-primary transition'>
                        <XMarkIcon className='w-4 h-4 min-w-4'/>
                    </AppButton>
                </div>
            </div>
        </div>
    )
}

export default PromptInstallPwa
