import SubmenuTab from "../../core/components/submenu-tab";
import {NavLink} from "react-router-dom";
import {CCToolsSubmenu} from "../constants/cc-tools-submenu";
import {useContext} from "react";
import {SessionCreatorToolsContext} from "../contexts/session-creator-tools-context";

const CCToolsMenu = () => {
    const baseLocation = "/cc-tools";
    const {products} = useContext(SessionCreatorToolsContext)!;

    const MappedSubmenu = CCToolsSubmenu
        .map(item => {
            if (item.href === "/digital-products") {
                item.badge = products.length || null;
            }
            return item;
        })

    return (
        <div className='overflow-y-clip overflow-x-auto flex gap-2 cursor-pointer'>
            {MappedSubmenu.map(item => (
                <NavLink
                    key={item.href}
                    to={baseLocation + item.href}
                    end={true}
                >
                    {({isActive}) => (
                        <SubmenuTab
                            badge={item.badge}
                            key={item.href}
                            text={item.text}
                            isActive={isActive}
                        />
                    )}
                </NavLink>
            ))}
        </div>
    );
}

export default CCToolsMenu
