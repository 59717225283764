import React from "react";
import MainLogo from "@assets/images/main-logo.png"
import ShortenedLogo from "@assets/images/main-logo-short.png"
import {classNames} from "../../../utils/class-names";

interface LogoProps {
    type: "shortened" | "complete",
    className?: string;
}

const Logo = (props: LogoProps) => {
    const {type = "complete", className} = props
    const logo = type === "complete" ? MainLogo : ShortenedLogo;

    return (
        <img
            src={logo}
            alt="Yurs logo"
            className={classNames(
                "object-cover object-center h-auto",
                className
            )}
        />
    )
}

export default Logo
