import {DigitalProductType} from "../../core/models/enums/digital-product-types.enum";

class DigitalProductCategoryService {
    private typeToCategoryMap: Record<DigitalProductType, string> = {
        [DigitalProductType.FILE]: "File",
        [DigitalProductType.TEXT]: "Text",
        [DigitalProductType.PRIVATE_CHAT]: "Private Chat",
        [DigitalProductType.PRIVATE_VOICE_CALL]: "Voice call",
        [DigitalProductType.PRIVATE_VIDEO_CALL]: "Video call",
        [DigitalProductType.PAYMENT]: "Payment",
    };

    public getCategoryName(type: DigitalProductType): string {
        return this.typeToCategoryMap[type] || 'Unknown';
    }
}

export default new DigitalProductCategoryService();
