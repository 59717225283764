import React from 'react';
import Coin from "../../core/components/coin";
import IconTypeTransaction from "./icon-type-transaction";
import {TransactionUi} from "../models/interfaces/transaction-ui.interface";
import CreditsConversionService from "../services/credits-conversion.service";
import {DateHelper} from "../../../utils/date-helper";

interface ElementTransactionHistoryProps {
    transaction: TransactionUi
}

const ElementTransactionHistory: React.FC<ElementTransactionHistoryProps> = (props: ElementTransactionHistoryProps) => {
    const {transaction} = props;

    return (
        <div className="flex items-center justify-between gap-3">
            <div className="flex items-center gap-4">
                <IconTypeTransaction type={transaction.type} direction={transaction.direction}/>
                <div>
                    <p className="font-semibold text-[0.94rem] leading-[1.125rem] mb-1">{transaction.title}</p>
                    <p className="text-gray text-xs leading-[0.9rem] mb-[0.125rem]">{transaction?.from}</p>
                    <p className="text-gray text-xs leading-[0.9rem]">{DateHelper.formatDate(transaction.date, 'DD.MM.YYYY, HH:mm')}</p>
                </div>
            </div>
            <div className="flex items-center gap-2 text-lg text-right">
                <span className='font-bold'>
                    {CreditsConversionService.formatNumberWithSpaceSeparator(transaction.amount)}
                </span>
                <Coin/>
            </div>
        </div>
    );
};

export default ElementTransactionHistory;
