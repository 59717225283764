import React, { ReactNode } from "react";

interface SwitchProps<T> {
    value: T;
    children: ReactNode;
}

interface CaseProps<T> {
    case: T;
    children: ReactNode;
}

const Switch = <T,>({ value, children }: SwitchProps<T>) => {
    const childrenArray = React.Children.toArray(children) as React.ReactElement<CaseProps<T>>[];
    return childrenArray.find(child => child.props.case === value) || null;
};

const Case = <T,>({ children }: CaseProps<T>) => <>{children}</>;

export { Switch, Case };
