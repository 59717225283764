import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import useAuth from '../../auth/hooks/use-auth';
import {useBecomeCreatorApi} from "../../core/hooks/use-become-creator-api";
import Base64Helper from "../../../utils/base64-helper";
import StepOne from "../containers/step-one";
import StepTwo from "../containers/step-two";
import StepThree from "../containers/step-three";
import StepFour from "../containers/step-four";
import {useNavigate} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/20/solid";
import AppButton from "../../common/components/app-button";
import AddFirstProductsView from "../../digital-products/views/add-first-product-view";
import LocalStorageService from "../../common/services/local-storage.service";
import {LocalStorageKeys} from "../../common/models/enums/local-storage-keys.enum";


type FormValues = {
    firstName: string;
    lastName: string;
    userName: string;
    bio: string;
    socialHandle: string;
    isTextMessages: boolean;
    textMessagePrice: number;
    isVoiceMessages: boolean;
    voiceMessagePrice: number;
    isVideoMessages: boolean;
    videoMessagePrice: number;
    freeTextMessageBlock: number;
    freeVoiceMessagesMin: number;
    freeVideoMessagesMin: number;
    profileImage: string;
    backgroundProfileImage: string;
    occupation: string;
    completionDate: Date;
    projectDetails: string;
}

function BecomeCreatorForm() {
    const [step, setStep] = useState(1);
    const [showProductForm, setShowProductForm] = useState(false);
    const [step1Ready, setStep1Ready] = useState(false);
    const {currentUser} = useAuth();
    const {sendRequestToBecomeCreator} = useBecomeCreatorApi();
    const navigate = useNavigate();
    const methods = useForm<FormValues>({
        defaultValues: {
            profileImage: currentUser?.profileImage || '',
            backgroundProfileImage: currentUser?.backgroundProfileImage || '',
            firstName: currentUser?.firstname || '',
            lastName: currentUser?.lastname || '',
            userName: currentUser?.username || '',
            bio: currentUser?.bio || '',
            socialHandle: '',
            isTextMessages: false,
            textMessagePrice: 0,
            isVoiceMessages: false,
            voiceMessagePrice: 0,
            isVideoMessages: false,
            videoMessagePrice: 0,
            freeTextMessageBlock: 0,
            freeVoiceMessagesMin: 0,
            freeVideoMessagesMin: 0,
            occupation: '',
            completionDate: new Date(),
            projectDetails: ''
        },
        mode: 'onSubmit',
        reValidateMode: 'onChange'
    });

    const handleChange = () => {
        setStep1Ready(checkStep1())
    }

    const checkIfNotExist = (value: string) => {
        return !(typeof value === 'undefined' || value === null || value.trim().length === 0)
    }
    const checkStep1 = (): boolean => {
        let values = methods.getValues();
        return !(checkIfNotExist(values.firstName) && checkIfNotExist(values.lastName) && checkIfNotExist(values.userName))
    }

    const saveRequest = async () => {
        await methods.handleSubmit(async (values) => {
            const requestBody = {
                username: values.userName,
                socialHandle: values.socialHandle,
                bio: values.bio,
                chatPrice: values.isTextMessages ? values.textMessagePrice : 0,
                callPrice: values.isVoiceMessages ? values.voiceMessagePrice : 0,
                videoCallPrice: values.isVideoMessages ? values.videoMessagePrice : 0
            };
            const formData = new FormData();
            formData.append('image', Base64Helper.base64ToBlob(values.profileImage)!);
            formData.append('request', new Blob([JSON.stringify(requestBody)], {type: "application/json"}));
            await sendRequestToBecomeCreator(formData).then(() => {
                nextStep();
                LocalStorageService.save(LocalStorageKeys.HAS_SENT_REQUEST_TO_BECOME_CC, true)
            });
        })();
    };

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);

    const handleShowProductForm = () => {
        setShowProductForm(true);
    }

    useEffect(() => {
        const isSent = LocalStorageService.get(LocalStorageKeys.HAS_SENT_REQUEST_TO_BECOME_CC);
        if (isSent) {
            setStep(4);
        } else {
            handleChange()
        }
    }, []);

    return (
        <FormProvider {...methods}>
            <form onChange={handleChange} onSubmit={(e) => e.preventDefault()}
                  className="flex justify-center w-full h-full min-h-full flex-grow">
                <div className="w-full">
                    {step === 1 && (
                        <StepOne
                            nextStep={nextStep}
                            prevStep={() => navigate(-1)}
                            step1Ready={step1Ready}/>
                    )}
                    {step === 2 && (
                        <StepTwo
                            prevStep={prevStep}
                            nextStep={nextStep}
                            messageDisabled={!methods.watch("isTextMessages")}
                            voiceDisabled={!methods.watch("isVoiceMessages")}
                            videoDisabled={!methods.watch("isVideoMessages")}
                        />
                    )}
                    {step === 3 && !showProductForm && (
                        <StepThree
                            onAddProduct={handleShowProductForm}
                            prevStep={prevStep}
                            saveRequest={saveRequest}/>
                    )}
                    {step === 4 && (
                        <StepFour username={methods.getValues('userName')}/>
                    )}
                </div>
            </form>
            {showProductForm && (
                <div className="space-y-3">
                    <AppButton
                        type="button"
                        label=""
                        className="bg-gray-light text-special-gray w-10 h-10 !rounded-full"
                        onClick={() => setShowProductForm(false)}
                    >
                        <ChevronLeftIcon className="w-5 min-w-5"/>
                    </AppButton>
                    <AddFirstProductsView onSuccess={() => setShowProductForm(false)}/>
                </div>
            )}
        </FormProvider>
    );
}

export default BecomeCreatorForm;
