import React, {useContext, useState} from "react";
import {SessionCreatorToolsContext} from "../contexts/session-creator-tools-context";
import AppButton from "../../common/components/app-button";
import CardDigitalProduct from "../components/card-digital-product";
import AppHamburgerMenu from "../../../layout/components/app-hamburger-menu";
import CCToolsMenu from "../components/cc-tools-menu";
import AppHeader from "../../../layout/app-header";
import AppContentWrapper from "../../../layout/components/app-content-wrapper";
import {DigitalProductCategories} from "../constants/categories-digital-products";
import {mapCategoriesWithBadges} from "../utils/map-categories-with-badges";
import OwnerDigitalProductsEmpty from "../components/owner-digital-products-empty";
import {useNavigate} from "react-router-dom";


const ListDigitalProductsView = () => {
    const {products} = useContext(SessionCreatorToolsContext)!;
    const [selectedCategory, setSelectedCategory] = useState("All");
    const navigate = useNavigate();

    const handleCategoryChange = (category: string) => {
        setSelectedCategory(category);
    };

    // const searchProducts = async (query: string): Promise<DigitalProduct[]> => {
    //     return fetchProducts().then(() => []);
    // };
    //
    // const handleSearchedProducts = (data: DigitalProduct[]) => {
    //     console.log(data)
    // }

    const categoriesWithCounts = mapCategoriesWithBadges(DigitalProductCategories, products);
    const goToFormProductHandler = () => navigate("../new-product")

    return (
        <>
            <AppHeader customContent={true}>
                <div className='flex justify-between flex-wrap gap-5'>
                    <div className='flex flex-col gap-5 w-full'>
                        <div className='flex items-center w-full'>
                            <div className='mr-3'><AppHamburgerMenu/></div>
                            <span className='font-bold text-2xl lg:text-[40px] text-primary-darken whitespace-nowrap'>
                                Creator Tools
                            </span>
                            <div className='w-full flex justify-end md:hidden'>
                                {products.length ? (
                                    <AppButton
                                        onClick={goToFormProductHandler}
                                        label='+'
                                        className='w-10 h-10 !rounded-full bg-secondary !p-0 !m-0 text-primary-darken font-bold'
                                    />
                                ): null}
                            </div>
                        </div>
                        <CCToolsMenu/>
                    </div>
                </div>
            </AppHeader>
            <AppContentWrapper className='p-4 bg-white rounded-t-[1.5rem] sm:pt-9 h-full'>
                {!products.length
                    ? <OwnerDigitalProductsEmpty/>
                    : (
                        <div className='flex flex-col gap-3 pb-6'>
                            {/*<SearchInput onSearch={searchProducts} onResults={handleSearchedProducts}/>*/}

                            <div className="flex items-center gap-2 my-4 border-b-2 border-gray-light">
                                {categoriesWithCounts.map((category) => (
                                    <button
                                        key={category.name}
                                        className={`relative top-[2px] flex items-center gap-2 p-1 text-sm font-semibold transition pb-2
                                    ${
                                            selectedCategory === category.name
                                                ? " text-black border-b-2 border-black"
                                                : " text-special-gray"
                                        }
                                `}
                                        onClick={() => handleCategoryChange(category.name)}
                                    >
                                        <span>{category.name}</span>
                                        {category.badge && (
                                            <span
                                                className={`flex items-center justify-center w-5 h-5 rounded-full text-xs font-semibold ${
                                                    selectedCategory === category.name ? "text-white bg-black" : "bg-gray-light text-special-gray"
                                                }`}
                                            >
                                    {category.badge}
                                </span>
                                        )}
                                    </button>
                                ))}
                            </div>

                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <AppButton
                                    onClick={goToFormProductHandler}
                                    label="+ Add product"
                                    className="hidden md:flex items-center justify-center border border-dashed border-special-gray w-full
                                    text-special-gray font-semibold hover:border-primary hover:text-primary !rounded-3xl"

                                />
                                {products.map((product) => <CardDigitalProduct key={product.id} product={product}/>)}
                            </div>
                        </div>
                    )
                }
            </AppContentWrapper>
        </>
    );
}

export default ListDigitalProductsView
