// StepThree.tsx
import React from 'react';
import {ChevronLeftIcon} from "@heroicons/react/20/solid";
import {classNames} from "../../../utils/class-names";
import AppButton from "../../common/components/app-button";
import addDigitalProducts from "@assets/illustrations/add-digital-products.png";


interface StepThreeProps {
    prevStep: () => void;
    saveRequest: () => void;
    onAddProduct: () => void;
}

const StepThree: React.FC<StepThreeProps> = ({ prevStep, saveRequest, onAddProduct }) => (

    <>
        <div className={classNames('space-y-3')}>
            <AppButton
                type="button"
                label=""
                className="bg-gray-light text-special-gray w-10 h-10 !rounded-full"
                onClick={prevStep}
            >
                <ChevronLeftIcon className="w-5 min-w-5" />
            </AppButton>
            <h1 className="font-bold text-4xl">Become a creator</h1>
            <div className="text-gray text-sm">Dummy text of the printing and typesetting industry lorem Ipsum is simply</div>
        </div>

        <img className="mt-10 max-h-[300px] mx-auto" src={addDigitalProducts} alt="Add Digital Products" />

        <div className={classNames('flex space-x-5 my-6 justify-center items-center')}>
            <div className="bg-primary-pastel rounded-full w-1 h-1 p-[1px] border-solid border-2 border-transparent"></div>
            <div className="bg-primary-pastel rounded-full w-1 h-1 p-[1px] border-solid border-2 border-transparent"></div>
            <div className="bg-white rounded-full w-3 h-3 p-[1px] border-solid border-[3px] border-primary"></div>
            <div className="bg-primary-pastel rounded-full w-1 h-1 p-[1px] border-solid border-2 border-transparent"></div>
        </div>

        <button
            type="button"
            className="mt-4 hover:bg-gray-400 hover:text-white text-gray font-bold py-2 px-4 rounded-full border-solid border-2 border-gray w-full"
            onClick={onAddProduct}
        >
            + Add product
        </button>

        <AppButton
            type="button"
            label="Finish"
            className="bg-primary text-white w-full !rounded-3xl mt-4"
            onClick={saveRequest}
        />
    </>
// <>
//     <div className={classNames('space-y-3')}>
//         <h1 className={classNames('font-bold text-4xl')}>Set your subscription details</h1>
//         <div className='text-gray-500 text-xs'>The printing and typesetting industry lorem Ipsum is simply dummy text</div>
//     </div>
//
//     <div className='flex justify-start space-x-2 mt-5 mb-5'>
//         <svg className='w-5 h-5' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6l277.2 0c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"/></svg>
//         <div>Subscription</div>  <SwitcherFormField className='float-right' name='isSubscription'/>
//     </div>
//     <TextFormField name='subscriptionPrice' label='Monthly Price'/>
//     <div className=' text-color-gray-500 text-xs text-gray flex justify-start mb-5'>
//         <InformationCircleIcon className='w-4 h-4'/> <div>You'll earn $82.50</div>
//     </div>
//
//     <CheckboxFormField className='appearance-auto' name='isYearlyDiscount' label='Activate yearly discount'/>
//     <div className='my-2'></div>
//     <TextFormField name='subscriptionPrice' label='Yearly discount'/>
//     <div className=' text-color-gray-500 text-xs text-gray flex justify-start'>
//         <InformationCircleIcon className='w-4 h-4'/> <div>Price after discount: 120yC / $41.25</div>
//     </div>
//
//     <div className='flex justify-start mt-5'>
//         <HandThumbUpIcon className='w-5 h-5'/> <div className='font-bold'>Subscribers free messages</div>
//     </div>
//
//     <div className='space-y-4 mt-4'>
//         <NumberFormField showArrows={true} min={1} step={1} name='freeTextMessageBlock' label='textMessageBlock'/>
//         <NumberFormField showArrows={true} min={1} step={1} name='freeVoiceMessagesMin' label='voiceMessagesMin'/>
//         <NumberFormField showArrows={true} min={1} step={1} name='freeVideoMessagesMin' label='videoMessagesMin'/>
//     </div>
//
//     <div className={classNames('flex space-x-5 my-6 justify-center')}>
//         <div className={classNames('bg-gray-500 rounded-full w-1.5 h-1.5 p-0.5 border-solid border-2 border-blue-600 mt-0.5')}></div>
//         <div className={classNames('bg-gray-500 rounded-full w-1.5 h-1.5 p-0.5 border-solid border-2 border-blue-600 mt-0.5')}></div>
//         <div className={classNames('bg-white rounded-full w-1.5 h-1.5 p-0.5 border-solid border-4 border-blue-600')}></div>
//         <div className={classNames('bg-gray-500 rounded-full w-1.5 h-1.5 p-0.5 border-solid border-2 border-transparent mt-0.5')}></div>
//         <div className={classNames('bg-gray-500 rounded-full w-1.5 h-1.5 p-0.5 border-solid border-2 border-transparent mt-0.5')}></div>
//     </div>
//     <button type="button" onClick={nextStep} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full w-full">
//         Next
//     </button>
// </>
);

export default StepThree;
