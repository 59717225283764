import { useEffect, useState } from 'react';

interface PositionOptions {
    preferredPositionY?: 'top' | 'bottom';
    preferredPositionX?: 'left' | 'right';
    offset?: number;
    shift?: number;
}

export const useDynamicPosition = (
    shouldUpdatePosition: boolean,
    triggerRef: React.RefObject<HTMLElement>,
    targetRef: React.RefObject<HTMLElement>,
    { preferredPositionY = 'bottom', preferredPositionX = 'right', offset = 8, shift = 10 }: PositionOptions = {}
) => {
    const [position, setPosition] = useState({ top: 0, left: 0 });

    const adjustPosition = () => {
        if (!targetRef.current || !triggerRef.current) return;

        const target = targetRef.current;
        const triggerElement = triggerRef.current;
        const triggerRect = triggerElement.getBoundingClientRect();
        const targetRect = target.getBoundingClientRect();

        let top = 0;
        let left = 0;

        // Ustal pozycję pionową (Y)
        if (preferredPositionY === 'top') {
            top = triggerRect.top - targetRect.height - offset;
        } else {
            top = triggerRect.bottom + offset;
        }

        // Ustal pozycję poziomą (X)
        if (preferredPositionX === 'left') {
            left = triggerRect.left - targetRect.width + shift;
        } else {
            left = triggerRect.left - shift;
        }

        // Dostosowanie pozycji w razie kolizji z krawędziami ekranu
        if (top < 0) {
            top = triggerRect.bottom + offset;
        } else if (top + targetRect.height > window.innerHeight) {
            top = triggerRect.top - targetRect.height - offset;
        }

        if (left < 0) {
            left = offset;
        } else if (left + targetRect.width > window.innerWidth) {
            left = window.innerWidth - targetRect.width - offset;
        }

        setPosition({ top, left });
    };

    useEffect(() => {
        if (shouldUpdatePosition) {
            adjustPosition();
            window.addEventListener('scroll', adjustPosition);
            return () => {
                window.removeEventListener('scroll', adjustPosition);
            };
        }
    }, [shouldUpdatePosition, preferredPositionY, preferredPositionX, offset, shift]);

    return position;
};
