import {useUserApi} from "../../core/hooks/use-user-api";
import useAuth from "../../auth/hooks/use-auth";
import useOnboarding from "../hooks/use-onboarding";
import {AuthenticatedUser} from "../../auth/models/interfaces/authenticated-user.interface";
import Logo from "../../core/components/logo";
import SkipOnBoarding from "../components/skip-onboarding";
import OnboardingStep from "../components/on-boarding-step";
import StepIndicator from "../../core/components/step-indicator";
import AppButton from "../../common/components/app-button";
import React from "react";
import {ccOnboardingSteps} from "../constants/cc-onboarding-steps";
import LocalStorageService from "../../common/services/local-storage.service";
import {LocalStorageKeys} from "../../common/models/enums/local-storage-keys.enum";
import {useNavigate} from "react-router-dom";

const CCOnBoarding = () => {
    const {changeCcOnBoarding} = useUserApi();
    const navigate = useNavigate();
    const {currentUser, updateAuthenticationField} = useAuth();
    const {
        isLastStep,
        currentStep,
        stepData,
        nextStep,
        goToStep
    } = useOnboarding(ccOnboardingSteps);

    const handleClick = async (isSkippedBoarding: boolean = false) => {
        if (isLastStep || isSkippedBoarding) {
            await changeCcOnBoarding();
            const updatedDataUser: AuthenticatedUser = {
                ...currentUser!,
                ccOnBoarding: true,
            }
            LocalStorageService.remove(LocalStorageKeys.LAST_STEP_ONBOARDING);
            updateAuthenticationField('user', updatedDataUser);
            navigate("/cc-tools",{
                preventScrollReset: true,
                replace: true,
            })
        } else {
            nextStep();
        }
    }

    return (
        <div className='flex flex-col items-center h-svh max-h-svh overflow-hidden'>
            <div className='flex flex-col justify-between w-full flex-grow overflow-y-auto p-4  '>
                <div className='flex justify-between gap-3 w-full md:mb-4'>
                    <Logo type='complete' className='w-[6rem] hidden md:block'/>
                    {!isLastStep && <SkipOnBoarding onSkip={handleClick.bind(this,true)}/>}
                </div>
                <OnboardingStep {...stepData} />
            </div>
            <div className='w-full text-center px-4 pb-4 pt-2 space-y-4 shadow-notification md:shadow-none'>
                <StepIndicator
                    totalSteps={ccOnboardingSteps.length}
                    currentStep={currentStep}
                    allowClick={true}
                    goToStep={goToStep}/>
                <AppButton
                    onClick={handleClick}
                    className='bg-primary !rounded-2xl h-fit !py-[9px] font-semibold text-sm leading-4 text-white w-full max-w-md'
                    label={isLastStep ? 'Set creator offer' : 'Next'}
                />
            </div>
        </div>
    );
}

export default CCOnBoarding
