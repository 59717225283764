import {useState} from "react";
import {EllipsisVerticalIcon, LinkIcon} from "@heroicons/react/24/solid";
import {classNames} from "../../utils/class-names";
import NotificationService from "../../features/common/services/notification.service";
import AppButton from "../../features/common/components/app-button";
import ClipboardHelper from "../../utils/clipboard-helper";

const PublicProfileSettingsButton = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleCopyLink = async () => {
        const currentUrl: string = window.location.href;
        await ClipboardHelper.copyText(
            currentUrl,
            () => NotificationService.success('Link has been copied to clipboard!', "Success", 5000),
            (error) => NotificationService.error('Failed to copy link', error, 'Error', 5000)
        );
        setIsOpen(false);
    };

    return (
        <div className="relative inline-block text-left">
            <AppButton
                onClick={toggleMenu}
                className={classNames(
                    "flex items-center justify-center w-10 h-10 bg-white text-gray border border-special-gray rounded-2xl",
                    isOpen && "!border-black"
                )}
                label=''>
                <EllipsisVerticalIcon className='w-6 h-6'/>
            </AppButton>

            {isOpen && (
                <div
                    className="absolute right-0 z-10 w-48 mt-2 bg-white rounded-2xl shadow-notification py-1 !font-semibold !text-sm">
                    <AppButton
                        onClick={handleCopyLink}
                        className="block w-full px-4 py-2 border-0 hover:text-primary"
                        label=''>
                        <div className='flex gap-2 items-center'>
                            <LinkIcon className='w-4 h-4'/>
                            <span className=''>Copy link to profile</span>
                        </div>
                    </AppButton>
                </div>
            )}
        </div>
    );
}

export default PublicProfileSettingsButton;
