import React, {useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import TextFormField from '../../common/components/forms/text-form-field';
import PasswordFormField from "../../common/components/forms/password-form-field";
import AppButton from "../../common/components/app-button";
import useAuth from "../hooks/use-auth";
import {RegisterBody} from "../models/interfaces/register-body.interface";
import SwitcherFormField from "../../common/components/forms/switcher-form-field";
import SelectFormField from "../../common/components/forms/select-form-field";
import {ChevronLeftIcon} from "@heroicons/react/24/solid";
import DictionaryService from "../../core/services/dictionary.service";
import NumberFormField from "../../common/components/forms/number-form-field";
import {schemaRegister} from "../constants/schema-register";
import Tooltip from "../../common/components/tooltip";


const RegisterForm = () => {
    const {register,loading} = useAuth();
    const formRef = useRef<HTMLFormElement>(null);
    const methods = useForm<RegisterBody>({
        resolver: yupResolver(schemaRegister),
        mode: 'onSubmit',
    });
    const navigate = useNavigate();

    const handleRegister = async () => {
        await methods.trigger();

        if (!methods.formState.isValid) {
            return;
        }

        const body = methods.getValues();
        try {
            await register(body);
            navigate('/auth/login')
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    return (
        <FormProvider {...methods}>
            <form ref={formRef} onSubmit={methods.handleSubmit(handleRegister)}
                  className="flex flex-col gap-y-6 p-4 max-w-md mx-auto h-full min-h-fit">

                <div className='flex-grow md:flex-grow-0 space-y-4'>
                    <div className="flex items-center gap-4">
                        <button type='button' className='p-3 bg-gray-light rounded-2xl text-primary-darken'
                                onClick={() => navigate("/welcome")}>
                            <ChevronLeftIcon className='w-4 h-4'/>
                        </button>
                        <h2 className="text-3xl font-bold">Create account</h2>
                    </div>

                    <TextFormField name="firstname" label="Firstname*" placeholder="Enter your firstname"/>
                    <TextFormField name="lastname" label="Lastname*" placeholder="Enter your lastname"/>
                    <SelectFormField
                        name='genderId'
                        parentWrapperRef={formRef}
                        labelKey='name' valueKey='id'
                        label='Select your gender*'
                        fetchOptions={() => DictionaryService.getGenders()}
                    />

                    <TextFormField name="email" label="Email*" placeholder="Enter your email"/>

                    <PasswordFormField name="password" label="Password*" placeholder="Create your password"
                                       showPasswordToggle={true}/>

                    <NumberFormField maxLength={9} type='number' name="contactNumber" label="Phone number*"
                                     placeholder="Enter your phone number"/>

                    <div className="flex flex-wrap gap-3 items-center text-sm text-special-gray">
                        <SwitcherFormField name='isAdult' label="I'm over 18*"/>
                        <SwitcherFormField name='emailNotifications' label="I want to receive email notifications"/>
                    </div>

                    <div className='flex text-special-gray text-xs leading-3 gap-1'>
                        <Tooltip/>
                        <span>
                            By using this site, you acknowledge you are at least 18 years old and you agree to our Terms of Service and Privacy Policy
                        </span>
                    </div>
                </div>

                <AppButton
                    type='submit'
                    loading={loading}
                    label={'Create'}
                    className='w-full text-white text-sm py-[9px] h-fit rounded-lg font-semibold bg-primary'/>
            </form>
        </FormProvider>
    );
};

export default RegisterForm;
